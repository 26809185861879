
import React, { Component } from 'react';

import str from '../../str';
import styles from './total.module.css'
import { geoCoordMap, provienceData } from '../util/geo';
import echarts from 'echarts/lib/echarts';
import 'echarts/map/js/china';
import geoJson from 'echarts/map/json/china.json';
import Api from '../../api/dataover'



//总览-设备统计
export default class Total_RealData extends Component {
    constructor(props) {
        super();
        this.state = {
            type: str.Area,
            datas: [],
            topFive: [],//所有区域排名前5的省份
            topFiveTotal: 0,
            total: 0,

            detection: [],//区域每天检测量

        };

    }

    componentDidMount() {

        this.getData();
        this.circle = setInterval(() => {
            this.getData();
        }, 1000*30);

    }



    componentWillUnmount() {

        clearInterval(this.circle)
        this.setState = (state, callback) => {
            return;
        };

    }
    render() {
        let {
            type,
            datas,
            topFive,
            topFiveTotal,
            total,
            detection
        } = this.state

        return (
            <div className={styles.DeviceItemContainer}>
                <div className={styles.ListItemTop}>
                    <div className={styles.flex_row} >
                        <div style={{ height: 30, width: 2, backgroundColor: '#00c9b2' }}> </div>
                        <div style={{ fontSize: 16, marginLeft: 10, color: "white" }} >设备</div>
                    </div>

                    <div className={styles.select_container}>
                        <div onClick={() => {
                            if (type == str.Active) { return }
                            this.setState({ type: str.Active }, () => { this.getData() })
                        }}>
                            <p className={type == str.Active ? styles.select_white : styles.unselect_white}>活跃程度</p>
                            {type == str.Active && (<div className={styles.lineh}> </div>)}
                        </div>


                        <div style={{ marginLeft: 20 }} onClick={() => {
                            if (type == str.Area) { return }
                            this.setState({ type: str.Area }, () => { this.getData() })
                        }}>
                            <p className={type == str.Area ? styles.select_white : styles.unselect_white}>区域分布</p>
                            {type == str.Area && (<div className={styles.lineh}> </div>)}
                        </div>
                    </div>

                </div>

                {type == str.Area && (<div style={{ position: 'absolute', left: 20, top: 580 }}>
                    <div style={{ fontSize: 16, color: "white", marginBottom: 10 }}>区域设备分布Top5</div>
                    {topFive.map((item, index) => {
                        return (
                            <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div style={{ color: "white", width: 70, textAlign: 'right', marginRight: 10, }} className={'singleline'}>{item.name}</div>
                                <div style={{ height: 16, width: 150 * item.value / topFiveTotal, backgroundColor: '#47d2ff' }}></div>
                                <div style={{ marginLeft: 10, color: "white" }}>{item.value + '台'}</div>
                            </div>
                        )
                    })}
                </div>)}



                {type == str.Active && (<div style={{ position: 'absolute', left: 20, top: 620 }}>
                    {colorsArr.map((item, index) => {
                        return (
                            <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div style={{ backgroundColor: item.color, width: 10, height: 10 }}></div>

                                <div style={{ marginLeft: 10, color: "white" }}>{item.text}</div>
                            </div>
                        )
                    })}
                </div>)}
                <div style={{ position: 'absolute', top: 40, left: 20, color: 'white' }}>设备总数<span style={{ fontSize: 28, marginLeft: 10, color: "#00c9b2" }}>{total}</span> <span>台</span> </div>
                {type == str.Area && (<div id="mainMap" className={styles.minwidth580} >


                </div>)}


                {type == str.Active && (<div id="activeMap" className={styles.minwidth580} >


                </div>)}



            </div>
        )
    }


    initECharts() {

        let {
            datas
        } = this.state
        echarts.registerMap('china', geoJson);
        const myChart = echarts.init(document.getElementById('mainMap'));
        myChart.setOption({
            tooltip: {
                show: true, // 不显示提示标签
                // formatter: '{b}', // 提示标签格式
                //鼠标放地图的某一块，显示的提示框
                formatter(params, ticket, callback) {
                   

                    return `设备数目<br />${params.name}：${params.data && params.data.InValue ? params.data.InValue : '0'}`


                },
                backgroundColor: '#ff7f50', // 提示标签背景颜色
                textStyle: { color: '#fff' } // 提示标签字体颜色
            },
            grid: {
                left: '10%',
                right: '10%',
                top: '10%',
                bottom: '10%',
                containLabel: true
            },
            geo: {
                map: 'china',
                roam: false,
                zoom: 1.2,
                tooltip: {
                    show: false, // 不显示提示标签
                },
                label: {
                    normal: {
                        show: false, // 显示省份标签
                        textStyle: { color: '#c71585' }// 省份标签字体颜色
                    },
                    emphasis: {// 对应的鼠标悬浮效果
                        show: false,
                        textStyle: { color: '#800080' }
                    }
                },
                itemStyle: {
                    color: '#42d5ff',
                    normal: {
                        borderWidth: 0.5, // 区域边框宽度
                        borderColor: '#000', // 区域边框颜色
                        areaColor: '#ffefd5', // 区域颜色
                        label: { show: false }
                    },
                    emphasis: {
                        show: false,
                        borderWidth: 0.5,
                        borderColor: '#4b0082',
                        areaColor: '#ffdead',
                    }
                },
            },
            series: [
                {
                    type: 'scatter',
                    coordinateSystem: 'geo',
                    data: this.convertData(data),
                    symbolSize: 1,
                    symbolRotate: 40,
                    label: {
                        normal: {
                            formatter: '{b}',
                            position: 'top',
                            show: true
                        },
                        emphasis: {
                            show: false
                        }
                    },
                    tooltip: {
                        show: false, // 不显示提示标签
                        // 显示提示的标签
                        formatter(name) {
                            return `Legend ${name}`;
                        }, // 提示标签格式
                        backgroundColor: '#fff', // 提示标签背景颜色
                        borderColor: '#ccc',
                        borderWidth: 5,
                        textStyle: { color: '#000' } // 提示标签字体颜色
                    },
                    itemStyle: {
                        normal: {
                            color: 'white'
                        }
                    }
                },
                {
                    type: 'map',
                    mapType: 'china',
                    roam: false,
                    zoom: 1.2,
                    tooltip: {
                        show: false, // 不显示提示标签
                    },
                    label: {
                        normal: {
                            show: false // 显示省份标签
                        },
                        emphasis: {
                            show: false,
                        }
                    },
                    itemStyle: {
                        normal: {
                            areaColor: '#181d30',

                            borderWidth: 0.5, // 区域边框宽度
                            borderColor: '#3c4157', // 区域边框颜色
                            label: { show: false }
                        },
                        emphasis: {
                            show: false,
                        }
                    },
                    // geoIndex: 0,
                    //鼠标放入地图显示提示框
                    tooltip: { show: true },
                    data: this.convertData(datas)
                }
            ],
        })
    }


    convertData(data) {
        const res = [];

        data.map(item => {
            const geoCoord = geoCoordMap[item.name];

            let color = '#fff'
            if (item.value > 0 && item.value <= 10) {
                color = '#42d5ff'
            } else if (item.value > 10 && item.value <= 20) {
                color = '#35bcf6'
            } else if (item.value > 20 && item.value <= 30) {
                color = '#28a3ed'
            } else if (item.value > 30 && item.value <= 40) {
                color = '#1a8be3'
            } else if (item.value > 40 && item.value <= 50) {
                color = '#0d72da'
            } else if (item.value > 50) {
                color = '#0d72da'
            }

            if (geoCoord) {
                res.push({
                    name: item.name,
                    // value: geoCoord.concat(data[i].area),
                    value: geoCoord,
                    InValue: item.value,
                    itemStyle: {
                        normal: {
                            areaColor: color,
                        },
                        emphasis: {
                            areaColor: '#FFBA00',
                        }
                    }
                });

            }
        })

        return res;
    }


    getData() {

        let {
            type
        } = this.state
        if (type == str.Area) {
            Api.getDeviceForProvince().then((res) => {
                if (res.data.code == 0) {
                    let provinces = this.dealArr(res.data.data.provinces)
                    this.dealdata(provinces)
                    let topfive = []
                    let total = 0
                    let all = 0
                    if (provinces.length > 5) {
                        for (var i = 0; i <= provinces.length - 1; i++) {

                            if (i <= 4) {
                                topfive.push(provinces[i])
                                total = total + provinces[i].value
                            }
                            all = all + provinces[i].value

                        }
                    } else {
                        for (var i = 0; i <= provinces.length - 1; i++) {
                            topfive.push(provinces[i])
                            total = total + provinces[i].value
                        }
                        all = total
                    }
                    this.setState({ datas: provinces, topFive: topfive, topFiveTotal: total, total: all }, () => {
                        this.initECharts();
                    })
                }
            },()=>{})
        } else if (type == str.Active) {
            this.getActiveData()
        }


    }

    dealdata(datas) {
        datas.map(item => {

            if (item.name.indexOf('新疆') > -1) {
                item.name = '新疆'

            }

            if (item.name.indexOf('广西') > -1) {
                item.name = '广西'
            }
        })
    }

    getActiveData() {
        Api.getActiveData().then((res) => {
            if (res.data.code == 0) {
                this.setState({ detection: res.data.data.detection }, () => {
                    this.initActiveECharts()
                })
            }
        },()=>{})
    }


    dealArr(arr) {
        var max;
        //遍历数组，默认arr中的某一个元素为最大值，进行逐一比较
        for (var i = 0; i < arr.length; i++) {
            for (var j = i; j < arr.length; j++) {
                if (arr[i].value < arr[j].value) {
                    //如果arr[j]大就把此时的值赋值给最大值变量max
                    max = arr[j];
                    arr[j] = arr[i];
                    arr[i] = max;
                }
            }
        }
    
        return arr

    }


    initActiveECharts() {

        let {
            detection
        } = this.state
        echarts.registerMap('china', geoJson);
        const myChart = echarts.init(document.getElementById('activeMap'));

        myChart.setOption({
            tooltip: {
                show: true, // 不显示提示标签
                // formatter: '{b}', // 提示标签格式
                //鼠标放地图的某一块，显示的提示框
                formatter(params, ticket, callback) {
                

                    return `今日检测量<br />${params.name}：${params.data && params.data.InValue ? params.data.InValue : '0'}`


                },
                backgroundColor: '#ff7f50', // 提示标签背景颜色
                textStyle: { color: '#fff' } // 提示标签字体颜色
            },
            grid: {
                left: '10%',
                right: '10%',
                top: '10%',
                bottom: '10%',
                containLabel: true
            },
            geo: {
                map: 'china',
                roam: false,
                zoom: 1.2,
                tooltip: {
                    show: false, // 不显示提示标签
                },
                label: {
                    normal: {
                        show: false, // 显示省份标签
                        textStyle: { color: '#c71585' }// 省份标签字体颜色
                    },
                    emphasis: {// 对应的鼠标悬浮效果
                        show: false,
                        textStyle: { color: '#800080' }
                    }
                },
                itemStyle: {
                    color: '#42d5ff',
                    normal: {
                        borderWidth: 0.5, // 区域边框宽度
                        borderColor: '#000', // 区域边框颜色
                        areaColor: '#ffefd5', // 区域颜色
                        label: { show: false }
                    },
                    emphasis: {
                        show: false,
                        borderWidth: 0.5,
                        borderColor: '#4b0082',
                        areaColor: '#ffdead',
                    }
                },
            },
            series: [
                {
                    type: 'scatter',
                    coordinateSystem: 'geo',
                    data: this.convertData(data),
                    symbolSize: 1,
                    symbolRotate: 40,
                    label: {
                        normal: {
                            formatter: '{b}',
                            position: 'top',
                            show: true
                        },
                        emphasis: {
                            show: false
                        }
                    },
                    tooltip: {
                        show: false, // 不显示提示标签
                        // 显示提示的标签
                        formatter(name) {
                            return `Legend ${name}`;
                        }, // 提示标签格式
                        backgroundColor: '#fff', // 提示标签背景颜色
                        borderColor: '#ccc',
                        borderWidth: 5,
                        textStyle: { color: '#000' } // 提示标签字体颜色
                    },
                    itemStyle: {
                        normal: {
                            color: 'white'
                        }
                    }
                },
                {
                    type: 'map',
                    mapType: 'china',
                    roam: false,
                    zoom: 1.2,
                    tooltip: {
                        show: false, // 不显示提示标签
                    },
                    label: {
                        normal: {
                            show: false // 显示省份标签
                        },
                        emphasis: {
                            show: false,
                        }
                    },
                    itemStyle: {
                        normal: {

                            areaColor: '#181d30',
                            borderWidth: 0.5, // 区域边框宽度
                            borderColor: '#fff', // 区域边框颜色
                            label: { show: false }
                        },
                        emphasis: {
                            show: false,
                        }
                    },
                    // geoIndex: 0,
                    //鼠标放入地图显示提示框
                    tooltip: { show: true },
                    data: this.convertActiveData(detection)
                }
            ],
        })
    }


    convertActiveData(data) {
        const res = [];

        data.map(item => {
            const geoCoord = geoCoordMap[item.name];

            let color = '#fff'
            if (item.value <= 5) {
                color = '#18c40f'
            } else if (item.value > 5 && item.value <= 10) {
                color = '#1c7cff'
            } else if (item.value > 10 && item.value <= 20) {
                color = '#ffd60a'
            } else if (item.value > 20) {
                color = '#f90003'
            }

            if (geoCoord) {
                res.push({
                    name: item.name,
                    // value: geoCoord.concat(data[i].area),
                    value: geoCoord,
                    InValue: item.value,
                    itemStyle: {
                        normal: {
                            areaColor: color,
                        },
                        emphasis: {
                            areaColor: '#FFBA00',
                        }
                    }
                });

            }
        })

        return res;
    }


}


const data = [
    { name: '黑龙江', area: '东北大区', type: 'areaCenterCity', count: '0' },
    { name: '吉林', area: '东北大区', type: 'areaCenterCity', count: '0' },
    { name: '辽宁', area: '东北大区', type: 'areaCenterCity', count: '0' },
    { name: '内蒙古', area: '其他', type: 'areaCenterCity', count: '0' },
    { name: '北京', area: '华北大区', type: 'areaCenterCity', count: '0' },
    { name: '天津', area: '华北大区', type: 'areaCenterCity', count: '0' },
    { name: '河北', area: '华北大区', type: 'areaCenterCity', count: '0' },
    { name: '山东', area: '华北大区', type: 'areaCenterCity', count: '0' },
    { name: '山西', area: '华北大区', type: 'areaCenterCity', count: '0' },
    { name: '江苏', area: '华东大区', type: 'areaCenterCity', count: '0' },
    { name: '上海', area: '华东大区', type: 'areaCenterCity', count: 0 },
    { name: '浙江', area: '华东大区', type: 'areaCenterCity', count: '0' },
    { name: '福建', area: '华南大区', type: 'areaCenterCity', count: '0' },
    { name: '广东', area: '华南大区', type: 'areaCenterCity', count: 0 },
    { name: '海南', area: '华南大区', type: 'areaCenterCity', count: '0' },
    { name: '台湾', area: '其他', type: 'areaCenterCity', count: '0' },
    { name: '香港', area: '其他', type: 'areaCenterCity', count: '0' },
    { name: '澳门', area: '其他', type: 'areaCenterCity', count: '0' },
    { name: '河南', area: '华北大区', type: 'areaCenterCity', count: '0' },
    { name: '安徽', area: '华中大区', type: 'areaCenterCity', count: 0 },
    { name: '江西', area: '华中大区', type: 'areaCenterCity', count: '0' },
    { name: '广东', area: '华南大区', type: 'areaCenterCity', count: '0' },
    { name: '陕西', area: '华西大区', type: 'areaCenterCity', count: '0' },
    { name: '湖北', area: '华中大区', type: 'areaCenterCity', count: '0' },
    { name: '湖南', area: '华中大区', type: 'areaCenterCity', count: '0' },
    { name: '广西', area: '华南大区', type: 'areaCenterCity', count: '0' },
    { name: '宁夏', area: '华西大区', type: 'areaCenterCity', count: '0' },
    { name: '重庆', area: '华西大区', type: 'areaCenterCity', count: '0' },
    { name: '贵州', area: '华西大区', type: 'areaCenterCity', count: '0' },
    { name: '四川', area: '华西大区', type: 'areaCenterCity', count: '0' },
    { name: '云南', area: '华西大区', type: 'areaCenterCity', count: 0 },
    { name: '甘肃', area: '华西大区', type: 'areaCenterCity', count: '0' },
    { name: '青海', area: '其他', type: 'areaCenterCity', count: '0' },
    { name: '西藏', area: '其他', type: 'areaCenterCity', count: '0' },
    { name: '新疆', area: '其他', type: 'areaCenterCity', count: '0' }
];

const colorsArr = [{ color: '#18c40f', text: '小于5辆/天' }, { color: '#1c7cff', text: '5-10辆/天' }, { color: '#ffd60a', text: '10-20辆/天' }, { color: '#f90003', text: '大于20辆/天' }]
