
import axios from './settingtoken';


export default {
    /**
     * 获取轮胎机会类型
     * @param {*} params 
     */
    getChanceTypeData(params) {
        return axios.get(`/api/tire/chance/type/`,{params:params})
    },

    //获取轮胎剩余使用年限统计
    getTimeData(params) {
        return axios.get(`/api/tire/dot/`,{params:params})
    },


    //获取轮胎品牌占比
    getbrands(params) {
        return axios.get(`/api/tire/brand/`,{params:params})
    },

    //获取轮胎规格排行
    getSpecRank(params) {
        return axios.get(`/api/tire/spec/`,{params:params})
    },

    //获取轮胎转化类型分析
    getTransformed(params) {
        return axios.get(`/api/tire/transformed/`,{params:params})
    },


}