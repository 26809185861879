import React, { Component } from 'react';

import str from '../../str';
import styles from './store.module.css'
import DropList from '../../component/droplist'
import echarts from 'echarts/lib/echarts'
//导入饼图
import 'echarts/lib/chart/pie'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/title'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/markPoint'
import ReactEcharts from 'echarts-for-react'
import Store_SpecSort from './store_specsort'
import Store_ForceSort from './store_forcesort'
import Api from '../../api/dataover_store'


//门店分析-销售备货分析
export default class Sale extends Component {
    constructor(props) {
        super();
        this.state = {
            selectTime: TimeArr[0],
            showTimeDrop: false,
            cicleDatas: [],
            type: str.Attribute_FindChance,
            barData: [],
            selectReason: cdArr[0],
            showReason: false,
            spec_order_list: [],
            brand_order_list: [],
            totalspec: 0,
            totalbrand: 0,
        };

    }

    //props发生变化时触发
    componentWillReceiveProps(props) {
       
        if (props.store !== this.props.store) {
            this.getCicleData(props.store)
            this.getRankData(props.store)
        }
    }

    componentDidMount() {

        this.getCicleData(this.props.store)
        this.getRankData(this.props.store)
    }
    render() {
        let {
            selectTime,
            showTimeDrop,
            circleOption,
            type,
            showReason,
            selectReason,
            spec_order_list,
            brand_order_list,
            totalspec,
            totalbrand
        } = this.state


        return (
            <div className={styles.sale_container}>
                <div className={styles.minwidth425}>
                    <div className={styles.flex_row} style={{ paddingLeft: 20, paddingTop: 20 }}  >
                        <div style={{ height: 30, width: 2, backgroundColor: '#00c9b2' }}> </div>
                        <div style={{ fontSize: 16, marginLeft: 10, color: "white" }} >{'销售&备货分析'}</div>
                    </div>

                    <div style={{ display: "flex", flexDirection: 'row', marginTop: 20 }} >
                        <img src={require('../../asset/Proportion.png')} style={{ width: 20, height: 20, marginLeft: 15,marginTop:3,marginRight:5 }}></img>  <span style={{ color: "white", fontSize: 16,marginLeft:10 }}>转化原因占比</span>
                    </div>

                    <div style={{ marginTop: 60, paddingTop: 20 }}>
                        <ReactEcharts option={this.getCicleOption()} />
                    </div>

                    <div style={{ marginTop: 20, padding: 20, display: 'flex', flexDirection: 'column', flex: 1, position: 'relative' }}>
                        <div onClick={() => { this.setState({ showReason: true }) }}
                            style={{ color: "white", fontSize: 16 }}>{'明细排行榜Top3--' + selectReason.name} <img src={require('../../asset/arrow_down.png')} style={{ width: 9, height: 5 }}></img>
                        </div>

                        {showReason && (<div style={{ position: 'relative' }}>
                            <div style={{ position: 'absolute', top: 0, left: 90 }}>
                                <DropList backgroundColor={'#181e31'} lineWidth={200} showLine={true} datas={cdArr} selectOnClick={(item) => {
                                    this.setState({ selectReason: item, showReason: false },()=>{
                                        this.getRankData(this.props.store)
                                    })
                                }} select={selectReason} ></DropList>
                            </div>
                        </div>)}


                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20, width: 520 }}>
                            <div style={{ marginRight: 20, fontSize: 16, minWidth: 70, color: 'white' }}>轮胎规格</div>
                            <div>
                                {spec_order_list.map((item, index) => {

                                    return (
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: index > 0 ? 10 : 0 }} key={index}>
                                            <div style={{ height: 16, width: totalspec > 0 ? 260 * item.count / totalspec : 260, backgroundColor: '#2075f1', }} >
                                            </div>
                                            <span style={{ marginLeft: 10, color: 'white' }}>{item.spec}</span>
                                        </div>
                                    )
                                })}

                            </div>


                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 40, width: 520 }}>
                            <div style={{ marginRight: 20, fontSize: 16, minWidth: 70, color: 'white' }}>轮胎品牌</div>
                            <div>
                                {brand_order_list.map((item, index) => {

                                    return (
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: index > 0 ? 10 : 0 }} key={index}>
                                            <div style={{ height: 16, width: totalspec > 0 ? 260 * item.count / totalbrand : 260, backgroundColor: '#2075f1', }} >
                                            </div>
                                            <span style={{ marginLeft: 10, color: 'white' }}>{item.brand + '   ' + item.count}</span>
                                        </div>
                                    )
                                })}

                            </div>


                        </div>


                    </div>

                </div>

                <Store_SpecSort store={this.props.store} selectTime={(item) => { this.setState({ selectTime: item }, () => { this.getCicleData( this.props.store); this.getRankData(this.props.store) }) }}></Store_SpecSort>
                <Store_ForceSort store={this.props.store}></Store_ForceSort>
            </div>
        )
    }


    //获得饼状图数据
    getCicleOption() {

        let {
            cicleDatas
        } = this.state


        let names = []
        let total = 0
        cicleDatas.map(item => {
            total = total + item.value
        })

        cicleDatas.map(item => {
            item.bfb = this.calculate100(item.value, total)
        })


        let option = {
            title: {

                text: '进店车次\n' + total,
                left: '39%',
                y: 'center',
                textStyle: {
                    color: '#fff'
                },
                textAlign: 'center'

            },
            emphasis: {
                show: false,
                formatter: '{d}%'
            },
            tooltip: {
                trigger: 'item',
                //提示框浮层内容格式器，支持字符串模板和回调函数形式。
                formatter: "{a} <br/>{b} : {c} ({d}%)"
            },
            legend: {
                orient: 'vertical',
                top: -5,
                right: 10,
                data: cicleDatas,
                textStyle: {
                    color: '#fff'
                },
                formatter: function (name) {
                    let str = ''
                    cicleDatas.map(item => {
                        if (item.name == name) {
                            str = item.bfb + '%'
                        }
                    })
                    return str + name;
                }

            },
            color: ['#00d2ce', '#2075f1', '#3cc3f5', '#e18832', '#9e0ee2'],
            series: [
                {
                    radius: [45, 135],
                    name: '百分比',
                    type: 'pie',
                    data: cicleDatas,
                    roseType: 'radius',
                    center: ['40%', '50%'],
                    label: {
                        show: true
                    },

                    emphasis: {
                        label: {
                            show: false
                        }
                    },
                }
            ]
        }

        return option
    }



    getCicleData(store) {
        let store_id=''

        if(store){
            if(store instanceof Array){
             
                store.map((item,index)=>{
                   if(index==0){
                       store_id=store_id+item.id
                   }else {
                       store_id=store_id+','+item.id
                   } 
                })
    
            }else{
               
         
               store_id=store.id 
            }
        }
        
        Api.getTransforFailData({ time: this.state.selectTime.value, store_id: store_id }).then(res => {
            if (res.data.code == 0) {
                let list = res.data.data.causes
                let temp={name:'其它',value:0}
                let arr=[];
                if (list.length > 0) {
                    list.map(item => {
                        item.value = item.count
                        if (item.cause == 'cd001') {
                            item.name = '价格高'
                            arr.push(item)
                        } else if (item.cause == 'cd002') {
                            item.name = '无库存'
                            arr.push(item)
                        } else if (item.cause == 'cd003') {
                            item.name = '调货时间长'
                            arr.push(item)
                        } else if (item.cause == 'cd004') {
                            item.name = '车辆用的少，不跑高速'
                            arr.push(item)
                        } else if (item.cause == 'cd005') {
                            item.name = '车主要换车'
                            arr.push(item)
                        } else  {
                           temp.value=temp.value+item.count
                        }
                    })
                    if(temp.value>0){
                        arr.push(temp) 
                    }
                   
                } else {
                    arr = [{ name: '价格高', value: 0 }, { name: '无库存', value: 0 }, { name: '调货时间长', value: 0 }]
                }


                this.setState({ cicleDatas: arr })
            }

        })
    }


    getRankData(store) {
        let store_id=''

        if(store){
            if(store instanceof Array){
             
                store.map((item,index)=>{
                   if(index==0){
                       store_id=store_id+item.id
                   }else {
                       store_id=store_id+','+item.id
                   } 
                })
    
            }else{
               
         
               store_id=store.id 
            }
        }
        Api.getRank({ time: this.state.selectTime.value, keyword: this.state.selectReason.value, store_id: store_id }).then(res => {
            if (res.data.code == 0) {
                let brands = res.data.data.brand_order_list;
                let specs = res.data.data.spec_order_list
                let temp1 = 0
                let temp2 = 0
                brands.map(item => {
                    temp1 = temp1 + item.count
                })
                specs.map(item => {
                    temp2 = temp1 + item.count
                })
                this.dealbrands(brands)
                this.dealspecs(specs)

                this.setState({
                    brand_order_list: brands,
                    totalbrand: temp1,
                    spec_order_list: specs,
                    totalspec: temp2
                })

            }
        })
    }

    dealbrands(arr) {
        let data = arr
        if (data.length < 3) {
            data.push({ brand: '无', count: 0 })
            this.dealbrands(data)
        }
    }

    dealspecs(arr) {
        let data = arr
        if (data.length < 3) {
            data.push({ spec: '无', count: 0 })
            this.dealspecs(data)
        }
    }

    calculate100(i, j) {
   
        if (i > 0 && j > 0) {

            return parseInt((i / j) * 100);
        }
        return 0;

    }

}

const TimeArr = [{ name: '本周', value: 'week' }, { name: '本月', value: 'month' }, { name: '今年', value: 'year' }]

const cdArr = [{ name: '价格高', value: 'cd001' }, { name: '无库存', value: 'cd002' }, { name: '调货时间长', value: 'cd003' }, { name: '车辆用的少，不跑高速', value: 'cd004' }, { name: '车主要换车', value: 'cd005' }, { name: '其它', value: 'cd010' }]

