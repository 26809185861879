import React, {Component} from 'react';
import PropTypes from 'prop-types';
import '../../src/app.css'
import { Tooltip } from 'antd';

//支持多选
export default class DropListMore extends Component {

  constructor(props) {
    super(props)
    this.state={
     
    }
  }
 
  render() {
     let select=this.props.select
     let showLine=this.props.showLine
     let backgroundColor=this.props.backgroundColor
    return (
      <div style={{backgroundColor:backgroundColor?backgroundColor:'',overflowY:'auto',overflowX:'hidden',maxHeight:this.props.height?this.props.height:  40*6}}>
        {this.props.datas.map((item,index)=>{
            return(
             <div key={index} onClick={()=>{this.props.selectOnClick(item)}}>
               {this.props.showTooltip&&( 
                 <Tooltip placement="right"    title={item.name}>
                 <div style={{display:"flex",width:this.props.lineWidth,flexDirection:'row',paddingLeft:10,alignItems:"center",height:35}} >
                  <img  style={{width:12,height:12,marginTop:5}} src={ select&& select.indexOf(item)>-1? require('../asset/point_select.png'): require('../asset/point_default.png')}></img>
                   <div  className ={'singleline'} style={{color:'white',marginLeft:10}}>{item.name}</div>
               </div></Tooltip>)}

               {!this.props.showTooltip&&(   <div style={{display:"flex",width:this.props.lineWidth,flexDirection:'row',paddingLeft:10,alignItems:"center",height:35}} >
                  <img  style={{width:12,height:12,marginTop:5}} src={ select&& select.indexOf(item)>-1? require('../asset/point_select.png'): require('../asset/point_default.png')}></img>
                   <div  className ={'singleline'} style={{color:'white',marginLeft:10}}>{item.name}</div>
               </div>)}
            
              {showLine&&<div style={{width:'100%',height:1,backgroundColor:'#43495c'}}></div>} 
               </div> 
            )
        })} 
      </div>
    )
  }
}


DropListMore.propTypes = {
    // 磨损状态数组，左前、右前、左后、右后
   lineWidth:PropTypes.number,
   datas:PropTypes.array,
   select:PropTypes.array,
   selectOnClick:PropTypes.func,
   showLine:PropTypes.bool,
   backgroundColor:PropTypes.string,
   showTooltip:PropTypes.bool
  }

  DropListMore.defaultProps={
   lineWidth:120,
   showLine:false ,
   showTooltip:false
  
}