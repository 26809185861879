

  const  calculate100=(i, j)=> {
        
        if (i > 0 && j > 0) {
           
         if((i / j) * 100>0&&(i / j) * 100<=1){
           return 1
         }

          return parseInt((i / j) * 100);
        }
        return 0;
    
      }
 

  export default {
    calculate100
  }