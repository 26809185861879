import React, { Component } from 'react';

import str from '../../str';
import styles from '../Total/total.module.css'

import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import List from 'react-virtualized/dist/commonjs/List';

import Progress from '../../component/progress'
import TimeDropList from '../../component/droplist'
import storestyles from '../Store/store.module.css'
import Api from '../../api/dataover_tire'
import Util from '../../Util';

const TimeArr = [{ name: '本周', value: 'week' }, { name: '本月', value: 'month' }, { name: '今年', value: 'year' }]
//轮胎分析-设备扫描量轮胎规格
export default class DeviceScan extends Component {
    constructor(props) {
        super();
        this.state = {

            datas: [],
            showTimeSelect: false,
            selectTime: TimeArr[0],
            total: 0
        };

    }

    componentDidMount() {
        this.getData()
    }


    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }
    render() {
        let {

            selectTime,
            showTimeSelect,
            total,
            datas
        } = this.state



        let timebottom = 0 - 36 * TimeArr.length

        return (
            <div style={{ width: 570, padding: 20,minWidth: 397}}>
                <div className={styles.ListItemTop}>
                    <div className={styles.flex_row} >
                        <div style={{ height: 30, width: 2, backgroundColor: '#00c9b2' }}> </div>
                        <div style={{ fontSize: 16, marginLeft: 10, color: "white" }} >设备扫描量-轮胎规格排行</div>
                    </div>

                    <div onClick={() => { this.setState({ showTimeSelect: !showTimeSelect }) }} style={{ fontSize: 16, color: "white" }}>{selectTime.name}  <img alt='' src={require('../../asset/jiantou_down.png')} ></img></div>
                    {showTimeSelect && (<div style={{ zIndex: 100, position: 'absolute', right: 0, bottom: timebottom }}>
                        <TimeDropList backgroundColor={'#181e31'} showLine={true} datas={TimeArr} selectOnClick={(item) => {
                            this.setState({ selectTime: item, showTimeSelect: false }, () => { this.getData() })
                        }} select={selectTime} ></TimeDropList>
                    </div>)}
                </div>


                <div style={{ height: 700, overflow: 'auto' }}>
                    {datas.map((item, index) => {
                        return (
                            <div key={index} style={{ marginTop: 20 }}>
                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5 }}>
                                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                                        <img src={require('../../asset/spec.png')} style={{ width: 24, height: 24 }}></img>
                                        <p style={{ color: "white", fontSize: 16 }}>{item.name}</p>
                                    </div>
                                    <div style={{ color: "white", fontSize: 16 }}>{item.count+'条('+Util.calculate100(item.count,total)+'%)'}</div>
                                </div>
                                <Progress percentageNum={Util.calculate100(item.count, total)} height={16} secondColor={'#1e95e6'}></Progress>
                            </div>
                        )
                    })}

                    {datas.length <= 0 && this.getNoDataView()}
                </div>
            </div>
        )
    }




    getData() {
        Api.getSpecRank({ time: this.state.selectTime.value }).then(res => {
            if (res.data.code == 0) {
                let list = res.data.data.spec_order_list
                let total = 0
                list.map(item => {
                    item.name = item.spec
                    total = total + item.count
                })
                this.setState({ datas: list, total: total })
            }
        })
    }

    getNoDataView() {
        return (
            <div style={{ textAlign: 'center', marginTop: 300, fontSize: 18 }}>没有数据</div>
        )
    }

}


