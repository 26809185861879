
import axios from 'axios';
import str from '../str';



// 创建axios 实例
const xhr = axios.create({
 
});

// request 拦截器
xhr.interceptors.request.use(
  config => {
    config.headers.Authorization=localStorage.getItem(str.TokenKey)
    
  return config;
  },
  err => {
    return Promise.reject(err);
  }
);
// response 拦截器
xhr.interceptors.response.use(
  response => {
    // token过期
    if (response.data.code === 1101) {
     
    } else if (response.data.code !== 0) {
     
    }
    return response;
  },
  error => {
   
   return Promise.reject(error)  // 返回接口返回的错误信息
  }
);

export default xhr;
