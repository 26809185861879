import React, { Component } from 'react';

import str from '../../str';
import styles from './tire.module.css'
import TimeDropList from '../../component/droplist'
import echarts from 'echarts/lib/echarts'
//导入饼图
import 'echarts/lib/chart/pie'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/title'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/markPoint'
import ReactEcharts from 'echarts-for-react'
import TireDecice from './tire_devicescan'
import Api from '../../api/dataover_tire'
import { Row, Col } from 'antd';
import '../../app.css'

//轮胎分析-轮胎市场
export default class TireMark extends Component {
    constructor(props) {
        super();
        this.state = {
            selectCity: '上海',
            cicleDatas: [],
            total: 0
        };

    }

    componentDidMount() {
        this.getData();
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }
    render() {
        let {
            cicleDatas,
            total
        } = this.state


        return (
            <div style={{ width: 1170, padding: 20, minWidth: 814 }}>
                <div style={{ display: "flex", flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div className={styles.flex_row} >
                        <div style={{ height: 30, width: 2, backgroundColor: '#00c9b2' }}> </div>
                        <div style={{ fontSize: 16, marginLeft: 10, color: "white" }} >轮胎市场分析</div>
                    </div>

                    {/**<div className={styles.flex_row} >
                        <img src={require('../../asset/dataover/position.png')} style={{ width: 28, height: 22 }}></img>
                        <div style={{ fontSize: 18, marginLeft: 5, color: "white" }} >上海</div>
                    </div> */}
                </div>

                <div style={{ display: 'flex', flexDirection: 'row' }}>

                    <div style={{ minWidth: 417, width: 600 }}>
                        <div className={styles.flex_row} style={{ padding: 20 }} >
                            <img src={require('../../asset/Proportion.png')} style={{ width: 20, height: 20 }}></img>
                            <div style={{ fontSize: 16, marginLeft: 10, color: "white" }} >轮胎品牌占比</div>
                        </div>

                        <div style={{ paddingTop: 20 }}>
                            <ReactEcharts option={this.getCicleOption()} style={{ height: 300 }} />
                        </div>
                       
                        <div style={{paddingLeft:40,paddingRight:40}}>
                            <Row>
                                {cicleDatas.map((item, index) => {

                                    return (
                                        <Col key={index} span={12} className={styles.col}>  
                                        <div style={{display:"flex",flexDirection:'row',justifyContent:'space-between'}}>
                                        <div style={{paddingLeft:20}}>{item.name } </div> 
                                        <div style={{paddingRight:20}}> {item.bfb + '%'}</div> 
                                        </div>
                                        </Col>
                                    )
                                })}
                            </Row>
                        </div>
                    </div>

                    <TireDecice></TireDecice>
                </div>
            </div>
        )
    }



    getData() {
        Api.getbrands().then(res => {
            if (res.data.code == 0) {
                let list = res.data.data.brands
                let temps = []
                let total = res.data.data.tires
                let qita = { name: '其他品牌', value: 0 }
                let temptotal = 0
                list.map(item => {
                    if (brandArr.indexOf(item.brand) > -1) {
                        let temp = {}
                        temp.bfb = this.calculate100(item.count, total)
                        temp.name = item.brand
                        temp.value = item.count
                        temps.push(temp)
                    } else {
                        qita.value = qita.value + item.count

                    }
                    temptotal = temptotal + item.count

                })

           
                qita.bfb = this.calculate100(qita.value, total)
                temps.push(qita)
                var max;
                for (var i = 0; i < temps.length; i++) {
                    
                    //外层循环一次，就拿arr[i] 和 内层循环arr.legend次的 arr[j] 做对比
                    for (var j = i; j < temps.length; j++) {
                        if (temps[i].value < temps[j].value) {
                            //如果arr[j]大就把此时的值赋值给最大值变量max
                            max = temps[j];
                            temps[j] = temps[i];
                            temps[i] = max;
                        }
                    }
                }
                this.setState({ total: res.data.data.tires, cicleDatas: temps })
            } else {

            }
        })

    }



    //获得饼状图数据
    getCicleOption() {

        let {
            cicleDatas,
            total
        } = this.state



        let temps = cicleDatas





        let option = {
            title: {

                text: '全国地区\n',

                y: '42%',
                x: 'center',
                textStyle: {
                    color: '#fff'
                }

            },
            emphasis: {
                show: false,
                formatter: '{d}%'
            },
            tooltip: {
                trigger: 'item',
                //提示框浮层内容格式器，支持字符串模板和回调函数形式。
                formatter: "{a} <br/>{b} : {c} ({d}%)"
            },
            legend: {
                show: false,
                orient: 'horizontal',
                top: 300,
                itemGap: 30,
                right: 90,
                data: temps,
                textStyle: {
                    color: '#fff'
                },
                formatter: function (name) {
                    let str = ''
                    temps.map(item => {
                        if (item.name == name) {
                            str = item.bfb + '%'
                        }
                    })
                    return str + name;
                }

            },
            color: ['#00d2ce', '#2075f1',  '#7721f1', '#ad21f1', '#64a4fb','#9b55ff','#009976','#1445b4','00abcf','#ff9f69','#3e59e1', '#3cc3f5', '#e18832', '#9e0ee2'],
            series: [
                {
                    radius: [40, 120],
                    name: '百分比',
                    type: 'pie',
                    data: temps,
                    roseType: 'radius',
                    center: ['50%', '45%'],
                    label: {
                        show: false
                    },

                    emphasis: {
                        label: {
                            show: false
                        }
                    },
                }
            ]
        }

        return option
    }


    calculate100(i, j) {

        if (i > 0 && j > 0) {
            if ((i / j) * 100 > 0 && (i / j) * 100 < 1) {
                return 1;
            }

            return parseInt((i / j) * 100);
        }
        return 0;

    }

}

const TimeArr = [{ name: '本周', value: 'week' }, { name: '本月', value: 'month' }, { name: '今年', value: 'year' }]
const brandArr = ['未录入','普利司通', '其他品牌', '玲珑', '韩泰', 'warrior', '玛吉斯', '朝阳', '米其林', 'Triangle', '固特异', '优科豪马', '佳通', '固铂', 'Gonti', 'MRF', '倍耐力', 'BFGoodrich', '邓禄普', 'Nexans']



