import React from 'react';
import logo from './logo.svg';
import Login from '../src/page/Login'
import Index from '../src/page/Index'

import { BrowserRouter as Router, Route,Switch} from "react-router-dom";
function App() {
  return (
  <Router>
   <div>
   <Route exact path="/" component={Login} key={'logindefault'} />
   <Route exact path="/login" component={Login} key={'login'} />
   <Route exact path="/index" component={Index} key={'index'} />
   </div>
  </Router>
  );
}

export default App;
