import React, { Component } from 'react';

import str from '../../str';
import styles from './tire.module.css'
import TireChance from './tire_chance'
import TireMark from './tire_mark'
import TireUser from './TireUser'

//轮胎分析
export default class Store extends Component {
    constructor(props) {
        super();
        this.state = {
          
        };

    }

    componentDidMount() {
      

    }

    componentWillUnmount(){
        this.setState = (state, callback) => {
            return;
          };
    }
    render() {
        let {
          
        } = this.state

        let user=JSON.parse(sessionStorage.getItem(str.UserDataKey))
        return (
            <div style={{backgroundColor:'#42475d'}}>
               {user.permissions.indexOf(1007)>-1&& (<TireChance></TireChance> ) } 
                 
            <div style={{display:'flex',flexDirection:'row',height:880}}> 
            {user.permissions.indexOf(1008)>-1&&( <TireMark></TireMark>  ) }         
            {user.permissions.indexOf(1009)>-1&& ( <TireUser></TireUser>) }
               </div>
                
               
            </div>
        )
    }




}



