import React, { Component } from 'react';

import str from '../../str';
import styles from './total.module.css'

import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import List from 'react-virtualized/dist/commonjs/List';
import Api from '../../api/dataover'
import Toast from '../../component/Toast/index'
import Loading from '../../component/Loading'

//总览-实时数据
export default class Total_RealData extends Component {
    constructor(props) {
        super();
        this.state = {
            type: str.Check,
            datas: [],
            reports: [],
            chances: [],
            showload: false
        };

    }

    componentDidMount() {
        this.getdata();
        this.circle=setInterval(() => {
           
            this.getdata()
        }, 1000*15);
    }


    componentWillUnmount() {
        clearTimeout(this.times)
        clearInterval(this.circle)
        this.setState = (state, callback) => {
            return;
        };
       
    }
    render() {
        let {
            type,
            datas,
            showload,
            chances,
            reports
        } = this.state
        let Arr = type == str.Check ? ['检测时间', '检测门店', '车牌号码', '销售机会 '] : ['转化时间', '转化门店', '转化内容', '操作人员']

        return (
            <div className={styles.ListItemContainer}>
                <div className={styles.ListItemTop}>
                    <div className={styles.flex_row} >
                        <div style={{ height: 30, width: 2, backgroundColor: '#00c9b2' }}> </div>
                        <div style={{ fontSize: 16, marginLeft: 10, color: "white" }} >实时数据</div>
                    </div>

                    <div className={styles.select_container}>
                        <div onClick={() => {
                            if (type == str.Check) { return }
                            this.setState({ type: str.Check }, () => {
                                this.getdata()
                            })

                        }}>
                            <p className={type == str.Check ? styles.select_white : styles.unselect_white}>检测数据</p>
                            {type == str.Check && (<div className={styles.lineh}> </div>)}
                        </div>


                        <div style={{ marginLeft: 10 }} onClick={() => {
                            if (type == str.Transfor) { return }
                            this.setState({ type: str.Transfor }, () => {
                                this.getdata()
                            })

                        }}>
                            <p className={type == str.Transfor ? styles.select_white : styles.unselect_white}>转化数据</p>
                            {type == str.Transfor && (<div className={styles.lineh}> </div>)}
                        </div>
                    </div>

                </div>



                <div className={styles.listHeader}>
                    <div className={styles.itemElementCenter25}>{Arr[0]}</div>
                    <div className={styles.itemElementCenter40}>{Arr[1]}</div>
                    <div className={styles.itemElement20}>{Arr[2]}</div>
                    <div className={styles.itemElement15}>{Arr[3]}</div>
                </div>

                {type == str.Check && (<div className={styles.listContainer} style={{ height: 700 }}>
                    {type == str.Check && this.state.reports.map((item, index) => {
                        return (<div className={styles.listItem} key={index}>
                            <div className={styles.itemElement25}>{item.device_time.split('T')[1]}</div>
                            <div className={styles.itemElement40}>{item.store_name}</div>
                            <div className={styles.itemElement20}>{item.plate_no}</div>
                            <div className={styles.itemElement15}>{item.is_chance=='yes'?'是':'否'}</div>
                        </div>)
                    })}


                </div>)}




                {type == str.Transfor && (<div className={styles.listContainer} style={{ height: 700 }}>



                    {this.state.chances.map((item, index) => {
                        return (<div className={styles.listItem} key={index}>
                            <div className={styles.itemElement25}>{item.changed_time.split('T')[1]}</div>
                            <div className={styles.itemElement40}>{item.store_name}</div>
                            <div className={styles.itemElement20}>{this.getTypeText(item.change_type)}</div>
                            <div className={styles.itemElement15}>{item.manage_staff}</div>
                        </div>)
                    })}



                </div>)}

                {showload && (<div style={{ position: 'absolute', left: '48%', top: '48%' }}><Loading></Loading></div>)}
            </div>
        )
    }


    getdata() {
     
        let {
            type
        } = this.state
        this.setState({ showload: true }, () => {
            if (type == str.Check) {
                Api.getRealCheckData().then((res) => {
                    this.setState({ showload: false })
                    if (res.data.code == 0) {
                        this.setState({ reports: res.data.data.reports })
                    }
                },()=>{})
            } else {
                Api.getRealChanceData().then((res) => {
                    this.setState({ showload: false })
                    if (res.data.code == 0) {
                        this.setState({ chances: res.data.data.chances })
                    }
                },()=>{})
            }
        })

        this.times = setTimeout(() => {
            this.setState({ showload: false })
            clearTimeout(this.times)
        }, 3000);


    }
    

getTypeText(type){
    let text = '其他'
    switch (type) {
        case 'tire-replace':
            text='轮胎更换'
            break
        case '4-wheel-alignment':
            text='四轮定位'
            break
        case 'dynamic-balance':
            text='动平衡'
            break
        case 'others':
            text='其他'
            break

    }
    return text
}

listItem({ key, index, style }) {
    let {
        datas
    } = this.state
    return (
        <div className={styles.listHeader}>
            <div>{datas[index].time}</div>
            <div>{datas[index].store}</div>
            <div>{datas[index].plate}</div>
            <div>{datas[index].chance}</div>
        </div>
    )
}

}


