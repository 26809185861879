import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from '../loginComponent.module.css'
import str from '../../str'
import '../../app.css'
import Api from '../../api/login'
import Toast from '../../component/Toast/index'
import UserApi from '../../api/user'

export default class password extends Component {
  constructor(props) {
    super();
    this.state = {
      sendTime: 0,
      phone: '',
      password: '',
      code: '',
      sendTime: 0
    };

  }

  componentDidMount() {


  }


  componentWillUnmount() {
    clearTimeout(this.Timer)
   
    this.setState = (state, callback) => {
        return;
    };
   
}  
  render() {
    let type = this.props.type
    let {
      sendTime,
      phone,
      password,
      code,
    } = this.state
    return (
      <div className={styles.container}>

        <div className={styles.select_white}>
          {this.props.title}
        </div>

        <img src={require('../../asset/close.png')} style={{ width: 28, height: 28, position: 'absolute', left: '90%', top: 20 }} onClick={() => { this.props.onClose && this.props.onClose() }}></img>

        <div style={{ marginTop: 10 }}>
          <div className={styles.LineContainer}> <img src={require('../../asset/Log_User.png')} style={{ width: 32, height: 32 }}  ></img> <input className={styles.input} placeholder={'请输入注册手机号'} value={phone} onChange={event => this.setState({ phone: event.target.value })}></input>  </div>
          <div className={styles.linehgray}></div>
        </div>


        <div style={{ marginTop: 30 }}>
          <div className={styles.LineContainer}>
            <img src={require('../../asset/SMS.png')} style={{ width: 32, height: 32 }}  ></img>
            <input className={styles.input} placeholder={'请输入验证码'} value={code} onChange={event => this.setState({ code: event.target.value })}></input>
            <button className={styles.codebutton} onClick={() => { this.getCode() }} >{sendTime > 0 ? sendTime + 's' : '发送验证码'}</button>
          </div>
          <div className={styles.linehgray}></div>
        </div>


        <div style={{ marginTop: 30 }}>
          <div className={styles.LineContainer}> <img src={require('../../asset/Password.png')} style={{ width: 32, height: 32 }}  ></img> <input className={styles.input} placeholder={'请输入密码'} value={password} onChange={event => this.setState({ password: event.target.value })} type={'password'}></input>  </div>
          <div className={styles.linehgray}></div>
        </div>


        <button className={styles.loginbutton} onClick={() => { this.findPassword() }}>确定</button>
      </div>
    )
  }

  getCode() {
    let {
      phone,
      sendTime
    } = this.state
    if (sendTime > 0) {
      return;
    }
    let type = this.props.type
    if (phone) {

      if (type == str.PassWordFind) {//找回密码
        Api.getFindCode({ mobile: phone }).then(res => {
          if (res.data.code == 0) {
            Toast.info('短信发送成功', 1000)
            this.Countdown();
          } else {
            Toast.info(res.data.message)
          }
        })
      } else {//修改密码
        UserApi.getUpdatePasswordCode({ mobile: phone }).then(res => {
          if (res.data.code == 0) {
            Toast.info('短信发送成功', 1000)
            this.Countdown();
          } else {
            Toast.info(res.data.message)
          }
        })

      }
    }
  }

  //找回密码
  findPassword() {
    let {
      sendTime,
      phone,
      password,
      code,
    } = this.state
    let type = this.props.type
    if (phone && code && password) {

      let params = {
        mobile: phone,
        code: code,
        password: password
      }
      if (type == str.PassWordFind) {//找回密码
        Api.FindPassword(params).then(res => {
          if (res.data.code == 0) {
           
            localStorage.setItem(str.UserName, phone)
            localStorage.setItem(str.UserPassword, password);
            this.props.UpdateSuccess && this.props.UpdateSuccess()
          }
          else {
            Toast.info(res.data.message)
          }
        })
      } else {//修改密码
        UserApi.UpdatePassword(params).then(res => {
          if (res.data.code == 0) {
          
            localStorage.setItem(str.UserName, phone)
            localStorage.setItem(str.UserPassword, password);
            this.props.UpdateSuccess && this.props.UpdateSuccess()
          }
          else {
            Toast.info(res.data.message)
          }
        })
      }

    }


  }


  // 开始倒计时
  Countdown() {
    this.setState({
      sendTime: 60
    });
    this.Timer = setInterval(() => {
      let _sendTime = this.state.sendTime;
      if (_sendTime > 0) {
        this.setState({
          sendTime: --this.state.sendTime
        });
      } else {
        clearInterval(this.Timer);
      }
    }, 1000);
  }


}

