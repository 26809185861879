import React, { Component } from 'react'
import PropTypes from 'prop-types'

import str from '../str'
import '../app.css'
import styles from './userlist.module.css'
import AddUser from './adduser'
import Api from '../api/user'
import { setAsHighDownDispatcher } from 'echarts/lib/util/graphic'
import Toast from '../component/Toast/index'
import UserDetail from './userdetail'
import SelectPermission from './selectpermission'
//权限 模块 用户列表
export default class UserList extends Component {
  constructor(props) {
    super();
    this.state = {
      total: 0,
      selectpage: 1,
      pages: 0,
      showAddUser: false,
      users: [],
      showPermission: false,
      selectuserids: [],
      total: 0,
      showUserDetail: false,
      user: null,//用户详情选中的用户,
      isDelete: false,//是否批量删除
      isAll: false,
      showSelectPermission: false,
      selectUser: null,
      search: '',
      isSearch: false
    };
    this.totaldata = []
    this.searchData = []


  }

  componentDidMount() {

    this.getData()
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };

  }
  render() {
    let {
      showAddUser,
      users,
      total,
      showUserDetail,
      user,
      isDelete,
      isAll,
      selectuserids,
      selectpermissons,
      showSelectPermission,
      selectUser,
      search,
      isSearch,
    } = this.state
    return (
      <div className={styles.container}>
        <div className={styles.title}>权限管理</div>

        <div style={{ display: "flex", flexDirection: 'row', marginTop: 20, alignItems: 'center',minWidth:1176 }}>
          <div className={styles.searchContaier}>
            <img src={require('../asset/dataover/Search.png')} style={{ height: 24, width: 24 }}></img>
            <input className={styles.input} placeholder={'搜索成员'} value={search} onChange={event => this.setState({ search: event.target.value })}></input>
          </div>

          <button style={{ marginLeft: 15 }} className={styles.buttonadd} onClick={() => { this.setState({ isSearch: true ,selectpage:1}, () => { this.doSearch() }) }}>{'确定'}</button>
          <button style={{ marginLeft: 15 }} className={styles.buttondelete} onClick={() => {
            this.setState({ isSearch: false ,search:'',selectpage:1}, () => {
              this.setCurrentPageData();
            });
            this.searchData = []

          }}>{'取消'}</button>
        </div>
        <div style={{ marginTop: 30,minWidth:1176 }}>
          <button className={styles.buttonadd} onClick={() => { this.setState({ showAddUser: true }) }}>添加人员</button>
          <button className={styles.buttondelete} onClick={() => {
            if (isDelete) {
              this.deleteUsers()
            } else {
              this.setState({ isDelete: true })
            }
          }}> {isDelete ? '确定' : '批量删除'}</button>
        </div>

        <div className={styles.listHeader} style={{ color: "white" ,minWidth:1176}}>
          <div style={{ width: 60, textAlign: 'center', }}> <img alt='' src={isAll ? require('../asset/check_Selected.png') : require('../asset/check_Defalt.png')} onClick={() => {
             if(isDelete){
              let temp = !isAll
              let temparr = []
              if (temp) {
                users.map(item => {
                  temparr.push(item.id)
                })
              } else {
  
              }
              this.setState({ isAll: temp, selectuserids: temparr })
             } 
         

          }}></img></div>
          <div style={{ textAlign: 'center', width: 60 }}>编号</div>
          <div style={{ width: 160, textAlign: 'center' }}>登录账号</div>
          <div style={{ width: 100, textAlign: 'center' }}>姓名</div>
          <div style={{ width: 100, textAlign: 'center' }}>部门</div>
          <div style={{ width: 100, textAlign: 'center' }}>职位</div>
          <div style={{ width: 200, textAlign: 'center' }}>创建时间</div>
          <div style={{ width: 200, textAlign: 'center' }}>登录时间</div>
          <div style={{ paddingLeft: 30 }}> 操作</div>
        </div>
        <div className={styles.listcontent}>
          {users.map((item, index) => {

            return (
              <div className={styles.listHeader} style={{ color: "white" }} key={index}>
                <div style={{ width: 60, textAlign: 'center', }}> <img alt='' src={selectuserids.indexOf(item.id) > -1 ? require('../asset/check_Selected.png') : require('../asset/check_Defalt.png')}
                  onClick={() => {
                    if(isDelete){
                      let temp = selectuserids
                      if (temp.indexOf(item.id) > -1) {
                        let ids = temp.indexOf(item.id)
                        temp.splice(ids, 1)
                      } else {
                        temp.push(item.id)
                      }
                      this.setState({ selectuserids: temp, isAll: temp.length == this.totaldata.length })
                    }
                 
                  }}></img></div>
                <div style={{ textAlign: 'center', width: 60 }}>{item.id}</div>
                <div className={styles.div160}>{item.mobile}</div>
                <div className={styles.div100}>{item.name}</div>
                <div className={styles.div100}>{item.department}</div>
                <div className={styles.div100}>{item.position}</div>
                <div className={styles.div200}>{item.create_time ? item.create_time.replace('T', ' ') : '-'}</div>
                <div className={styles.div200}>{item.last_login_time ? item.last_login_time.replace('T', ' ') : '-'}</div>
                <div style={{ display: 'flex', flexDirection: 'row' ,flexShrink:0}} >
                  <button className={styles.buttongreen}
                    onClick={() => {
                      let selectpermission = []
                      if (item.position == '总经理') {
                        selectpermission = totalarr.concat(tire, store)
                        this.setState({ selectpermissons: selectpermission })
                      } else {
                        let all = totalarr.concat(tire, store)

                        all.map(item1 => {
                          if (item.permissions.indexOf(item1.value) > -1) {

                            selectpermission.push(item1)
                          }
                        })

                        this.setState({ selectpermissons: selectpermission, selectUser: item }, () => {
                          this.setState({ showSelectPermission: true })
                        })
                      }
                    }}>查询板块授权</button>
                  <button className={styles.buttongreen} onClick={() => { this.setState({ user: item }, () => { this.setState({ showUserDetail: true }) }) }} >详情</button>
                  <button className={styles.buttongreen} onClick={() => { this.deleteUser(item) }}>删除</button>
                </div>

              </div>
            )
          })}
        </div>

        <div className={styles.bottomContainer}>
          <div style={{color:"white"}}>{`共${total}条,每页10条`}</div>
          <div className={styles.bottomRight}>


            <div className={styles.arrowContainer} onClick={() => { this.lastPage() }}> <img src={require('../asset/arrowleft.png')} style={{ height: 14, width: 15 }}></img></div>

            {this.getPages()}

            <div className={styles.arrowContainer} onClick={() => { this.nextPage() }}> <img src={require('../asset/arrowright.png')} style={{ height: 14, width: 15 }}></img></div>
          </div>
        </div>

        {showAddUser && (<div className={styles.useraddContainer}>
          <AddUser Close={() => { this.setState({ showAddUser: false }) }} addUser={() => { this.setState({ showAddUser: false }, () => { this.getData() }) }}></AddUser>
        </div>
        )}

        {showUserDetail && (<div className={styles.useraddContainer}>
          <UserDetail data={user} Close={() => { this.setState({ showUserDetail: false }) }} SaveSuccess={() => { this.setState({ showUserDetail: false }, () => { this.getData() }) }}></UserDetail>
        </div>)}

        {showSelectPermission && <div style={{ position: 'absolute', top: -100, left: 500, zIndex: 30 }}>
          <SelectPermission datas={selectpermissons} savePermission={(arr) => { this.setState({ showSelectPermission: false }) }} position={{ name: selectUser.position, id: '1' }} Close={() => { this.setState({ showSelectPermission: false }) }}></SelectPermission>
        </div>}
      </div>
    )
  }

  lastPage() {
    let {
      selectpage
    } = this.state
    let temp = selectpage
    if (temp > 1) {
      temp--
    }

    this.setState({ selectpage: temp }, () => {
      this.setCurrentPageData()
    })
  }


  nextPage() {
    let {
      selectpage,
      pages
    } = this.state
    let temp = selectpage
    if (temp < pages) {
      temp++
    }

    this.setState({ selectpage: temp }, () => {
      this.setCurrentPageData()
    })
  }


  getPages() {
    let {
      pages,
      selectpage
    } = this.state
    let temp = []
    if (pages > 0) {
      for (let i = 1; i <= pages; i++) {
        temp.push(i)
      }
      return (
        <div >
          {temp.map((item, index) => {
            return (<span key={index} className={item == selectpage ? styles.pageSingleSelect : styles.pageSingle} onClick={() => { this.setState({ selectpage: item }, () => { this.setCurrentPageData() }) }}>{item}</span>)
          })}
        </div>
      )
    }

  }

  getData() {

    Api.getUserList().then(res => {
      if (res.data.code == 0) {
        let total = res.data.data.users.length
        this.totaldata = res.data.data.users
        this.setState({ total: res.data.data.users.length }, () => {
          if (this.state.isSearch) {
            this.doSearch()//筛选一下数据 
          } else {
            this.setCurrentPageData()
          }

        })
      }
    })
  }

  //设置当前页的数据
  setCurrentPageData() {
    let {
      selectpage,
      search,
      isSearch
    } = this.state

    if (isSearch) {

      let usertemp = []
      this.searchData.map((item, index) => {
        if (index >= selectpage * 10 - 10 && index < selectpage * 10) {
          usertemp.push(item)
        }
      })
      this.setState({ users: usertemp ,pages:this.pageCount(this.searchData.length,10)})
    } else {
      let temp = []
      this.totaldata.map((item, index) => {
        if (index >= selectpage * 10 - 10 && index < selectpage * 10) {
          temp.push(item)
        }
      })

      this.setState({ users: temp ,pages:this.pageCount(this.totaldata.length,10)},()=>{
  
      })
    }


  }

  deleteUser(user) {

 
    Api.deleteUser({ params: { uid: user.id } }).then(res => {
      if (res.data.code == 0) {
        Toast.success()
        this.getData();
      }else{
        Toast.info(res.data.message)
      }
    })

  }


  deleteUsers() {
    let {
      selectuserids
    } = this.state
    if (selectuserids.length <= 0) {

      this.setState({ isDelete: false })
      return
    }
    let ids = ''

    selectuserids.map((item, index) => {
      if (index == 0) {
        ids = ids + item
      } else {
        ids = ids + ',' + item
      }
    })

    Api.deleteUsers({ params: { ids: ids } }).then(res => {
      if (res.data.code == 0) {
        Toast.success()
        this.setState({ isDelete: false ,selectuserids:[]}, () => {
          this.getData()
        })
      } else {
        Toast.info(res.data.message)
      }
    })

  }

  //根据总数和每页显示数目算出有多少页
  pageCount(totalnum, limit) {
    return totalnum > 0 ? ((totalnum < limit) ? 1 : ((totalnum % limit) ? (parseInt(totalnum / limit) + 1) : (totalnum / limit))) : 0;
  }

  //从所有数据中筛选出搜索的数据
  doSearch() {
    let {
      search,
      isSearch
    } = this.state
    let temp = []
    this.totaldata.map((item, index) => {
      if (item.mobile.indexOf(search) > -1 || item.name.indexOf(search) > -1) {
        temp.push(item)
      }
    })
    this.searchData = temp
    this.setCurrentPageData();
  }

}

const totalarr = [{ name: '实时数据', value: 1001 }, { name: '设备分布', value: 1002 }, { name: '数据排行榜', value: 1003 }, { name: '累计数据', value: 1004 }]
const store = [{ name: '单店分析', value: 1005 }, { name: '多店分析', value: 1006 }]
const tire = [{ name: '轮胎机会', value: 1007 }, { name: '轮胎市场', value: 1008 }, { name: '客户画像', value: 1009 }]


