import React, { Component } from 'react';
import LoginComponent from '../login/loginComponent'
import str from '../str';
import style from './css/login.module.css'
import PassWord from '../login/password/password'
import Toast from '../component/Toast/index';


//设备信息
export default class Login extends Component {
  constructor(props) {
    super();
    this.state = {
      type: str.Login  //登录或者找回密码
    };

  }

  componentDidMount() {
    document.title = '登录'

  }


  render() {
    let {
      type
    } = this.state


    return (
      <div className={style.container}>

        <div className={style.bjimg}></div>
        

        <div className={style.logincontainer}>
          {type == str.Login && (<LoginComponent toForget={() => {
            this.setState({ type: str.PassWordFind })
          }}
            Login={() => {
              this.props.history.push('Index')
            }}
          ></LoginComponent>)}
          {type == str.PassWordFind && (<PassWord 
            onClose={()=>{this.setState({type:str.Login})}}
            title={'找回密码'}
            type={str.PassWordFind}
            UpdateSuccess={() => {
                 Toast.success()
                  this.setState({type: str.Login})  
            }}></PassWord>)}
        </div>

        
        <div style={{ position: 'absolute', width:'100%',bottom: '10%',textAlign:'center',color:'white'}}>          
          <a href="https://beian.miit.gov.cn/" target="_blank">Copyright © 2024   沪ICP备2024050241号-1</a>
        </div>
      </div>
      
    )
  }




}
