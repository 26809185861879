import React, { Component } from 'react';

import str from '../../str';
import styles from './tire.module.css'
import TimeDropList from '../../component/droplist'
import echarts from 'echarts/lib/echarts'
//导入饼图
import 'echarts/lib/chart/pie'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/title'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/markPoint'
import ReactEcharts from 'echarts-for-react'
import Api from '../../api/dataover_tire'


//轮胎分析-轮胎机会分析
export default class TireChance extends Component {
    constructor(props) {
        super();
        this.state = {
            chanceTypeOption: '',
            chanceTypeData: [],
            timeData:[]
        };

    }

    componentDidMount() {

        this.getChanceTypeData()
        this.getTimeData()
    }

    componentWillUnmount(){
        this.setState = (state, callback) => {
            return;
          };
    }
    render() {
        let {

        } = this.state


        return (
            <div style={{ height: 500, padding: 20 }}>
                <div style={{ display: "flex", flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between',minWidth:1336 }}>
                    <div className={styles.flex_row} >
                        <div style={{ height: 30, width: 2, backgroundColor: '#00c9b2' }}> </div>
                        <div style={{ fontSize: 16, marginLeft: 10, color: "white" }} >轮胎机会分析</div>
                    </div>

                  {/**  <div className={styles.flex_row} >
                        <img src={require('../../asset/dataover/position.png')} style={{ width: 28, height: 22 }}></img>
                        <div style={{ fontSize: 18, marginLeft: 5, color: "white" }} >上海</div>
                    </div>*/} 
                </div>
                <div style={{ display: "flex", flexDirection: 'row',  }}>

                    <div style={{ width: 1170, display: 'flex', flexDirection: 'column', minWidth:814}}>

                        <div className={styles.flex_row} style={{ marginTop: 20, paddingLeft: 20 }}>
                            <img src={require('../../asset/Quantity.png')} style={{ width: 28, height: 22 }}></img>
                            <div style={{ fontSize: 16, marginLeft: 10, color: "white" }} >机会类型</div>
                        </div>



                        <div style={{ marginTop: 20 }}>
                            <ReactEcharts option={this.getChanceTypeBarOption()} style={{ height: 360 }} />
                        </div>
                    </div>



                    <div style={{ minWidth: 522 }}>

                        <div className={styles.flex_row} style={{ marginTop: 20, paddingLeft: 20 }}>
                            <img src={require('../../asset/Quantity.png')} style={{ width: 28, height: 22 }}></img>
                            <div style={{ fontSize: 16, marginLeft: 10, color: "white" }} >轮胎使用年限到期</div>
                        </div>



                        <div style={{ marginTop: 20 }}>
                            <ReactEcharts option={this.getUseBarOption()} style={{ height: 360 }} />
                        </div>
                    </div>


                </div>


            </div>
        )
    }





    getChanceTypeBarOption() {
        let {
            chanceTypeData,
        } = this.state


        let xdatas = [];
        let datas = [];
        chanceTypeData.map(item => {
            xdatas.push(item.name)
            datas.push(item.value)
        })
        let option = {
            color: ['#3398DB'],
            tooltip: {
                trigger: 'axis',
                axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                    type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                }
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: [
                {
                    type: 'category',
                    data: xdatas,
                    axisTick: {
                        alignWithLabel: true,

                    },
                    axisLabel: {
                        show: true,
                        textStyle: {
                            color: '#fff'
                        }
                    }
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    axisLabel: {
                        show: true,
                        textStyle: {
                            color: '#fff'
                        }
                    }
                }
            ],
            series: [
                {
                    name: '数量',
                    type: 'bar',
                    barWidth: '40%',
                    data: datas,
                    itemStyle: {
                        normal: {
                            color: function (params) {
                                let colorList = ['#00d2ce', '#2075f1', '#3cc3f5', '#e18832', '#9e0ee2', '#d57dff'];

                                return colorList[params.dataIndex]

                            }
                        }
                    }
                }
            ]
        };

        return option
    }


    getUseBarOption() {
        let {
            timeData,
        } = this.state


        let xdatas = [];
        let datas = [];

        let temps=timeData
        temps.map(item => {
            xdatas.push(item.name)
            datas.push(item.value)
        })
        let option = {
            color: ['#3398DB'],
            tooltip: {
                trigger: 'axis',
                axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                    type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                }
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: [
                {
                    type: 'category',
                    data: xdatas,
                    axisTick: {
                        alignWithLabel: true,

                    },
                    axisLabel: {
                        show: true,
                        textStyle: {
                            color: '#fff'
                        }
                    }
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    axisLabel: {
                        show: true,
                        textStyle: {
                            color: '#fff'
                        }
                    }
                }
            ],
            series: [
                {
                    name: '数量',
                    type: 'bar',
                    barWidth: '40%',
                    data: datas,
                    itemStyle: {
                        normal: {
                            color: function (params) {
                                let colorList = ['#d57dff', '#d57dff', '#d57dff'];

                                return colorList[params.dataIndex]

                            }
                        }
                    }
                }
            ]
        };

        return option
    }

    getTimeData() {
        Api.getTimeData().then(res => {
            if (res.data.code == 0) {
                this.setState({timeData:res.data.data.type_list})
            }
        })

    }

    getChanceTypeData() {
        Api.getChanceTypeData().then(res => {
            if (res.data.code == 0) {
               this.setState({chanceTypeData:res.data.data.type_list})
            }
        })

    }





    calculate100(i, j) {
   
        if (i > 0 && j > 0) {

            return parseInt((i / j) * 100);
        }
        return 0;

    }

}

const TimeArr = [{ name: '本周', value: 'week' }, { name: '本月', value: 'month' }, { name: '今年', value: 'year' }]



const TireUserTime = [{ name: '<3个月', value: 100 }, { name: '<6个月', value: 60 }, { name: '<12个月', value: 76 },
]