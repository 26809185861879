import React, { Component } from 'react';

import str from '../../str';
import styles from '../Total/total.module.css'

import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import List from 'react-virtualized/dist/commonjs/List';

import Progress from '../../component/progress'
import TimeDropList from '../../component/droplist'
import storestyles from './store.module.css'
import { attachTypeApi } from 'antd/lib/message';
import Api from '../../api/dataover_store'
import Util from '../../Util';

const TimeArr = [{ name: '本周', value: 'week' }, { name: '本月', value: 'month' }, { name: '今年', value: 'year' }]
//门店-执行力排行榜
export default class ForceSort extends Component {
    constructor(props) {
        super();
        this.state = {
            type: str.Force_Check,
            datas: [],
            showTimeSelect: false,
            selectTime: TimeArr[0],
            total: 0
        };

    }



    //props发生变化时触发
    componentWillReceiveProps(props) {

        if (props.store !== this.props.store) {
            this.getData(props.store)
        }
    }

    componentDidMount() {
        this.getData(this.props.store)

    }
    render() {
        let {
            type,
            selectTime,
            showTimeSelect,
            datas,
            total
        } = this.state



        let timebottom = 0 - 36 * TimeArr.length

        return (
            <div className={storestyles.SpecSortContainer}>
                <div className={styles.ListItemTop}>
                    <div className={styles.flex_row} >
                        <div style={{ height: 30, width: 2, backgroundColor: '#00c9b2' }}> </div>
                        <div style={{ fontSize: 16, marginLeft: 10, color: "white" }} >执行力排行榜Top10</div>
                    </div>

                    <div onClick={() => { this.setState({ showTimeSelect: !showTimeSelect }) }} style={{ fontSize: 18, color: "white" }}>{selectTime.name} <img alt='' src={require('../../asset/jiantou_down.png')} ></img> </div>
                    {showTimeSelect && (<div style={{ zIndex: 100, position: 'absolute', right: 0, bottom: timebottom }}>
                        <TimeDropList backgroundColor={'#181e31'} showLine={true} datas={TimeArr} selectOnClick={(item) => {
                            this.setState({ selectTime: item, showTimeSelect: false }, () => { this.getData(this.props.store) })
                        }} select={selectTime} ></TimeDropList>
                    </div>)}
                </div>

                <div style={{ display: "flex", flexDirection: "row", justifyContent: 'flex-end', alignItems: 'center', marginTop: 10 }}>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: 72 }} onClick={() => { this.setState({ type: str.Force_Service }, () => { this.getData(this.props.store) }) }}>
                        <div style={{ fontSize: 16, color: "white", marginBottom: 5 }}>轮胎服务</div>
                        {type == str.Force_Service && <div className={styles.lineh72}></div>}
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: 20, width: 72 }} onClick={() => { this.setState({ type: str.Force_Sale }, () => { this.getData(this.props.store) }) }}>
                        <div style={{ fontSize: 16, color: "white", marginBottom: 5 }}>轮胎销售</div>
                        {type == str.Force_Sale && <div className={styles.lineh72}></div>}
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: 20, width: 72 }} onClick={() => { this.setState({ type: str.Force_Track }, () => { this.getData(this.props.store) }) }}>
                        <div style={{ fontSize: 16, color: "white", marginBottom: 5 }}>跟踪%</div>
                        {type == str.Force_Track && <div className={styles.lineh72}></div>}
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: 20, width: 72 }} onClick={() => { this.setState({ type: str.Force_Check }, () => { this.getData(this.props.store) }) }}>
                        <div style={{ fontSize: 16, color: "white", marginBottom: 5 }}>检测量</div>
                        {type == str.Force_Check && <div className={styles.lineh72}></div>}
                    </div>

                </div>

                {datas.map((item, index) => {
                    return (
                        <div key={index} style={{ marginTop: 20 }}>
                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5 }}>
                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                                    <img src={require('../../asset/Log_User.png')} style={{ width: 24, height: 24 }}></img>
                                    <div className='singleline' style={{ color: "white", fontSize: 16, marginLeft: 10 }}>{item.name}</div>
                                </div>
                                {type == str.Force_Check && (<div style={{ color: "white", fontSize: 16 }}>{item.count + '辆(' + Util.calculate100(item.count, total) + '%)'}</div>)}
                                {type == str.Force_Track && (<div style={{ color: "white", fontSize: 16 }}>{item.count + '辆(' + Util.calculate100(item.count, item.device_number) + '%)'}</div>)}
                                {type == str.Force_Sale && (<div style={{ color: "white", fontSize: 16 }}>{item.count + '条(' + Util.calculate100(item.count, total) + '%)'}</div>)}
                                {type == str.Force_Service && (<div style={{ color: "white", fontSize: 16 }}>{item.count + '辆(' + Util.calculate100(item.count, total) + '%)'}</div>)}
                            </div>
                            {type == str.Force_Check && (<Progress percentageNum={Util.calculate100(item.count, total)} height={16}></Progress>)}
                            {type == str.Force_Track && (<Progress percentageNum={Util.calculate100(item.count, item.device_number)} height={16}></Progress>)}
                            {type == str.Force_Sale && (<Progress percentageNum={Util.calculate100(item.count, total)} height={16}></Progress>)}
                            {type == str.Force_Service && (<Progress percentageNum={Util.calculate100(item.count, total)} height={16}></Progress>)}
                        </div>
                    )
                })}

                {datas.length <= 0 && this.getNoDataView()}

            </div>
        )
    }

    getData(store) {

        let {
            type
        } = this.state
        let store_id = ''

        if (store) {
            if (store instanceof Array) {

                store.map((item, index) => {
                    if (index == 0) {
                        store_id = store_id + item.id
                    } else {
                        store_id = store_id + ',' + item.id
                    }
                })


            } else {


                store_id = store.id
            }
        }
        Api.getDoRank({ time: this.state.selectTime.value, keyword: this.state.type, store_id: store_id }).then(res => {
            if (res.data.code == 0) {
                let total = 0;
                let datas = res.data.data.top10_list;
                var max;

                if (type == str.Force_Track) {
                    for (var i = 0; i < datas.length; i++) {

                        //外层循环一次，就拿arr[i] 和 内层循环arr.legend次的 arr[j] 做对比
                        for (var j = i; j < datas.length; j++) {
                            if (Util.calculate100(datas[i].count, datas[i].device_number) < Util.calculate100(datas[j].count, datas[j].device_number)) {
                                //如果arr[j]大就把此时的值赋值给最大值变量max
                                max = datas[j];
                                datas[j] = datas[i];
                                datas[i] = max;
                            }
                        }
                    }

                } else {
                    for (var i = 0; i < datas.length; i++) {

                        //外层循环一次，就拿arr[i] 和 内层循环arr.legend次的 arr[j] 做对比
                        for (var j = i; j < datas.length; j++) {
                            if (datas[i].count < datas[j].count) {
                                //如果arr[j]大就把此时的值赋值给最大值变量max
                                max = datas[j];
                                datas[j] = datas[i];
                                datas[i] = max;
                            }
                        }
                    }
                }


                total = res.data.data.total

                this.setState({ datas: datas, total: total })
            }

        })
    }

    getNoDataView() {
        return (
            <div style={{ textAlign: 'center', marginTop: 300, fontSize: 18 }}>没有数据</div>
        )
    }

}


