import React, { Component } from 'react';
import styles from './css/index.module.css'
import PropTypes from 'prop-types'
import PassWord from '../login/password/password'
import UserList from '../permission/userlist'
import UserDetail from '../permission/userdetail'
import str from '../str';
import Total from '../dataover/Total/total'
import Store from '../dataover/Store/store'
import Tire from '../dataover/Tire/tire'
import Loading from '../component/Loading'
import AddFirst from '../permission/addfirstuser'
import Toast from '../component/Toast/index';
//数据总览
export default class Index extends Component {
  constructor(props) {
    super();
    this.state = {
      useraction: false,
      showPassWord: false,   //是否修改密码
      showPermission: false,//权限
      showUserDetail: false,//用户详情
      Type: str.All,  //
      showload: false,
      showAddUser: false
    };

  }

  componentDidMount() {
    document.title = '主页'
    this.userdata = JSON.parse(sessionStorage.getItem(str.UserDataKey))
    if (!this.userdata) {
      this.props.history.push('Login')
    }
  }

  componentWillUnmount() {

    if (this.timer) {
      clearTimeout(this.timer)
    }
    this.setState = (state, callback) => {
      return;
    };

  }


  render() {
    let {
      useraction,
      showPassWord,
      showPermission,
      showUserDetail,
      Type,
      showload,
      showAddUser
    } = this.state


    return (
      <div className={styles.container}>
        <div className={styles.topContainer} onClick={()=>{ if(useraction)  {this.setState({useraction:false})}  }}>

          <div className={styles.topleft}>
            <div className={styles.topItem}> <img src={require('../asset/dataover/logo.png')} style={{ width: 116, height: 32 }}></img>   <div className={styles.white18}>数据管理系统</div> </div>
            <div className={styles.topItem} style={{ marginLeft: 80 }} onClick={() => { this.selectBox(str.All) }}>
              <img src={Type == str.All ? require('../asset/dataover/Nav_Overview_Selected.png') : require('../asset/dataover/Nav_Overview_Default.png')} style={{ width: 40, height: 40 }}></img>
              <div className={styles.gray12} style={{ color: Type == str.All ? "white" : '#7f8598' }}>总览</div> </div>
            <div className={styles.topItem} style={{ marginLeft: 80 }} onClick={() => { this.selectBox(str.Store) }} >
              <img src={Type == str.Store ? require('../asset/dataover/Nav_Stores_Selected.png') : require('../asset/dataover/Nav_Stores_Default.png')} style={{ width: 40, height: 40 }}></img>
              <div className={styles.gray12} style={{ color: Type == str.Store ? "white" : '#7f8598' }}>门店分析</div> </div>
            <div className={styles.topItem} style={{ marginLeft: 80 }} onClick={() => { this.selectBox(str.Tire) }}>
              <img src={Type == str.Tire ? require('../asset/dataover/Nav_Tires_Selected.png') : require('../asset/dataover/Nav_Tires_Default.png')} style={{ width: 40, height: 40 }}></img>
              <div className={styles.gray12} style={{ color: Type == str.Tire ? "white" : '#7f8598' }}>轮胎分析</div> </div>
          </div>


        
            {/** <div className={styles.toprightsearch}>
              <img src={require('../asset/dataover/Search.png')} style={{ height: 24, width: 24 }}></img>
              <input className={styles.input} placeholder={'查找项目'} ></input>
            </div>*/}
            <img src={require('../asset/Log_User.png')} style={{ width: 32, height: 32,zIndex:220 }} onClick={() => {
              let temp = useraction
              this.setState({ useraction: !temp })
            }}></img>
          
        </div>

        {/** 显示用户操作选项  */}
        {useraction && (<div className={styles.userpostion}>
          <div>
            <div className={styles.useritem} onClick={() => { this.updatePassword() }}>
              <img src={require('../asset/Password.png')} style={{ width: 24, height: 24, marginRight: 50 }}></img>
              <div className={styles.white18}>修改密码</div>
            </div>
            {this.userdata.position == '总经理' && (<div className={styles.useritem} onClick={() => { this.setState({ Type: str.Permission, useraction: false }) }} >
              <img src={require('../asset/Permissions.png')} style={{ width: 24, height: 24, marginRight: 50 }}></img>
              <div className={styles.white18}>权限管理</div>
            </div>)}

            {this.userdata.role == 0 && (<div className={styles.useritem} onClick={() => { this.setState({ showAddUser: true, useraction: false }) }} >
              <img src={require('../asset/Permissions.png')} style={{ width: 24, height: 24, marginRight: 50 }}></img>
              <div className={styles.white18}>添加一级账户</div>
            </div>)}
            <div className={styles.useritem} onClick={() => { this.LoginOut() }}>
              <img src={require('../asset/Login_off.png')} style={{ width: 24, height: 24, marginRight: 50 }}></img>
              <div className={styles.white18} >退出</div>
            </div>

          </div>
        </div>)}


        {showPassWord && (<div className={styles.logincontainer}>

          <PassWord title={'修改密码'} UpdateSuccess={()=>{  Toast.success();this.setState({ showPassWord: false }) } }   onClose={() => { this.setState({ showPassWord: false }) }}  type={'update'}></PassWord>
        </div>)}

        {Type == str.Permission && (<div className={styles.userlistContainer}>
          <UserList ></UserList>
        </div>)}



        {Type == str.All && (<Total user={this.userdata}></Total>)}
        {Type == str.Store && (<Store user={this.userdata}  showLoad={()=>{
      
      
        }}></Store>)}
        {Type == str.Tire && (<Tire user={this.userdata}></Tire>)}
        {showload && (<div style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', }}>
          <div style={{ position: 'absolute', left: '48%', top: '45%' }}>
            <Loading style={{ width: '100%', height: '100%' }}></Loading></div></div>)}

        {showAddUser && (<div style={{ position: 'absolute', left: '20%', top: '20%',zIndex:30 }}>

          <AddFirst Close={() => { this.setState({ showAddUser: false }) }} addUser={() => { Toast.success(); this.setState({ showAddUser: false }) }}></AddFirst>
        </div>)}

      </div>
    )
  }
  selectBox(type) {
    let {
      showload,
      Type
    } = this.state

    if (type == Type) {
      return
    }
    this.setState({ Type: type, showload: true }, () => {
      this.timer = setTimeout(() => {
        this.setState({ showload: false })
      }, 1500);
    })

  }



  LoginOut() {
    localStorage.setItem(str.TokenKey, '')
    sessionStorage.setItem(str.UserDataKey, '')
    this.props.history.push(str.LoginPage)
  }

  updatePassword() {
    this.setState({ showPassWord: true, useraction: false })
  }
}


