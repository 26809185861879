import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './loginComponent.module.css'
import str from '../str'
import '../app.css'
import Api from '../api/login'
import User from '../api/user'
import Toast from '../component/Toast/index'


export default class LoginComponent extends Component {
    constructor(props) {
        super();
        this.state = {
            type: str.PassWord,
            phone: '',
            password: '',
            code: '',
            sendTime: 0
        };

    }

    componentDidMount() {

        this.getLocalData();
    }


    componentWillUnmount() {
        clearTimeout(this.Timer)

        this.setState = (state, callback) => {
            return;
        };

    }
    render() {
        let {
            type,
            phone,
            password,
            code,
            sendTime
        } = this.state
        return (
            <div className={styles.container}>

                <div className={styles.select_container}>
                    <div onClick={() => {
                        if (type == str.PassWord) { return }
                        this.setState({ type: str.PassWord })
                    }}>
                        <p className={type == str.PassWord ? styles.select_white : styles.unselect_white}>密码登录</p>
                        {type == str.PassWord && (<div className={styles.lineh}> </div>)}
                    </div>


                    <div style={{ marginLeft: 20 }} onClick={() => {
                        if (type == str.Code) { return }
                        this.setState({ type: str.Code })
                    }}>
                        <p className={type == str.Code ? styles.select_white : styles.unselect_white}>短信验证</p>
                        {type == str.Code && (<div className={styles.lineh}> </div>)}
                    </div>
                </div>

                <div className={styles.linehgray1}></div>

                <div style={{ marginTop: 50 }}>
                    <div className={styles.LineContainer}> <img src={require('../asset/Log_User.png')} style={{ width: 32, height: 32 }}  ></img> <input className={styles.input} placeholder={'请输入电话号码'} value={phone} onChange={event => this.setState({ phone: event.target.value })} ></input>  </div>
                    <div className={styles.linehgray}></div>
                </div>


                {type == str.Code && (<div style={{ marginTop: 30 }}>
                    <div className={styles.LineContainer}>
                        <img src={require('../asset/SMS.png')} style={{ width: 32, height: 32 }}  ></img>
                        <input className={styles.input} placeholder={'请输入验证码'} value={code} onChange={event => this.setState({ code: event.target.value })}   ></input>
                        <button className={styles.codebutton} style={{ width: 100 }} onClick={() => { this.sendCode() }}>{sendTime > 0 ? sendTime + 's' : '发送验证码'}</button>
                    </div>
                    <div className={styles.linehgray}></div>
                </div>)}


                {type == str.PassWord && (<div style={{ marginTop: 30 }}>
                    <div className={styles.LineContainer}> <img src={require('../asset/Password.png')} style={{ width: 32, height: 32 }}  ></img> <input type={'password'} className={styles.input} placeholder={'请输入密码'} value={password} onChange={event => this.setState({ password: event.target.value })}></input>  </div>
                    <div className={styles.linehgray}></div>
                    <div className={styles.forget} onClick={() => { this.props.toForget() }}>忘记密码?</div>
                </div>)}


                <button className={styles.loginbutton} onClick={() => { this.Login() }}>登录</button>
            </div>
        )
    }


    sendCode() {
        let {
            phone,
            sendTime
        } = this.state
          
        if(sendTime>0){
            return
        }

        if (phone) {
            Api.getLoginCode({ mobile: phone }).then(res => {
                if (res.data.code == 0) {
                    Toast.info('短信发送成功', 1000)
                    this.Countdown();
                } else {
                    Toast.info(res.data.messege)
                }
            })
        }

    }


    Login() {
        let {
            type,
            phone,
            password,
            code
        } = this.state
        if (type = str.PassWord && phone && password) {
            Api.LoginPassword({ username: phone, password: password }).then(res => {
                if (res.data.code == 0) {

                    localStorage.setItem(str.TokenKey, res.data.data.token)
                    this.getUser();
                } else {
                    Toast.info(res.data.message)
                }
            })
        } else if (type = str.Code && phone && code) {
            Api.LoginCode({ username: phone, code: code }).then(res => {
                if (res.data.code == 0) {
                    localStorage.setItem(str.TokenKey, res.data.data.token)
                    this.getUser();
                } else {
                    Toast.info(res.data.message)
                }
            })
        }


    }


    getUser() {
        let {
            phone,
            password
        } = this.state

        User.getUserData().then(res => {
            if (res.data.code == 0) {

                sessionStorage.setItem(str.UserDataKey, JSON.stringify(res.data.data.user))
                localStorage.setItem(str.UserName, phone)
                localStorage.setItem(str.UserPassword, password);
                this.props.Login();
            }
        })
    }


    // 开始倒计时
    Countdown() {
        this.setState({
            sendTime: 60
        });
        this.Timer = setInterval(() => {
            let _sendTime = this.state.sendTime;
            if (_sendTime > 0) {
                this.setState({
                    sendTime: --this.state.sendTime
                });
            } else {
                clearInterval(this.Timer);
            }
        }, 1000);
    }


    getLocalData() {
        let username = localStorage.getItem(str.UserName)
        let password = localStorage.getItem(str.UserPassword)
        this.setState({ phone: username, password: password })
    }

}

