
import axios from './settingtoken';


export default {
    /**
     * 获取实时检测数据
     * @param {*} params 
     */
    getRealCheckData() {
        return axios.get(`/api/overview/device/`)
    },

    //获取实时机会数据
    getRealChanceData() {
        return axios.get(`/api/overview/chance/`)
    },


    //获取设备所在省份的数量
    getDeviceForProvince() {
        return axios.get(`/api/overview/devices/province_number/`)
    },

    //获取累计数据
    getTotalData() {
        return axios.get(`/api/overview/total/`)
    },

    //获取地域排名前十数据
    getLocalTopTen(params) {
        return axios.get(`/api/overview/top_ten/`,{params:params})
    },

    //获取设备活跃
    getActiveData(){
        return axios.get(`/api/overview/scan/province_number/`)  
    }

}