import React, { Component } from 'react'
import PropTypes from 'prop-types'

import str from '../str'
import '../app.css'
import styles from './adduer.module.css'
import Api from '../api/user'
import ApiStore from '../api/dataover_store'
import DropList from '../component/droplist'
import DropListMore from '../component/droplistmore'
import SelectPermission from '../permission/selectpermission'
import Toast from '../component/Toast/index'
import { Input } from 'antd';
//选择权限
export default class addUser extends Component {
    constructor(props) {
        super();
        this.state = {
            phone: '',
            name: '',
            department: [],
            position: [],
            stores: [],
            selectdepartment: null,
            selectposition: null,
            selectstores: [],
            selectpermissons: [],
            showSelectDepartment: false,
            showSelectPostions: false,
            showSelectStore: false,
            showSelectPermission: false

        };

    }

    componentDidMount() {
        this.getStores();
        this.getDepartment();

    }
    render() {

        let {
            phone,
            name,
            department,
            stores,
            position,
            selectdepartment,
            selectposition,
            selectstores,
            selectpermissons,
            showSelectDepartment,
            showSelectPostions,
            showSelectStore,
            showSelectPermission
        } = this.state

        return (

            <div className={styles.container}>
                <div className={styles.topContainer}> <span>添加成员</span> <img src={require('../asset/close.png')} style={{ width: 20, height: 20 }} alt="" onClick={() => { this.props.Close() }}></img></div>

                <div className={styles.itemContainer}>
                    <div className={styles.leftText}>手机号码:</div>
                    <Input className={styles.input} value={phone} onChange={event => this.setState({ phone: event.target.value })}></Input>
                </div>


                <div className={styles.itemContainer}>
                    <div className={styles.leftText}>{'姓名:'}</div>
                    <Input className={styles.input} value={name} onChange={event => this.setState({ name: event.target.value })}></Input>
                </div>


                <div className={styles.itemContainer} >
                    <div className={styles.leftText}>所在部门:</div>

                    <div className={styles.rightBox} onClick={() => { this.setState({ showSelectDepartment: !showSelectDepartment,showSelectPostions:false,showSelectStore:false }) }}>{selectdepartment ? selectdepartment.name : ''}</div>
                    <div className={styles.rightBoxImg} onClick={() => { this.setState({ showSelectDepartment:!showSelectDepartment,showSelectPostions:false,showSelectStore:false }) }}> <img src={require('../asset/arrow_down.png')}></img> </div>

                    {showSelectDepartment && (<div style={{ position: 'absolute', left: 130, top: 30, zIndex: 20 }}>
                        <DropList lineWidth={220} select={selectdepartment} showLine={true} datas={department} backgroundColor={'#181e31'}
                            selectOnClick={(item => {
                                this.setState({ showSelectDepartment: false, selectdepartment: item, selectposition: null }, () => {
                                    this.getPostions();
                                })
                            })}
                        ></DropList>
                    </div>)}
                </div>



                <div className={styles.itemContainer}>
                    <div className={styles.leftText}>所处职位:</div>
                    <div className={styles.rightBox} onClick={() => { this.setState({ showSelectPostions: ! showSelectPostions,showSelectDepartment:false,showSelectStore:false}) }} >{selectposition ? selectposition.name : ''}</div>
                    <div className={styles.rightBoxImg} onClick={() => { this.setState({ showSelectPostions: ! showSelectPostions,showSelectDepartment:false,showSelectStore:false }) }}> <img src={require('../asset/arrow_down.png')}></img> </div>

                    {showSelectPostions && <div style={{ position: 'absolute', left: 130, top: 30, zIndex: 20 }}>
                        <DropList lineWidth={220} select={selectposition} showLine={true} datas={position} backgroundColor={'#181e31'}
                            selectOnClick={(item => { this.setState({ showSelectPostions: false, selectposition: item }) })}
                        ></DropList>
                    </div>}
                </div>


                <div className={styles.itemContainer}>
                    <div className={styles.leftText}>选择门店:</div>
                    <div className={styles.rightBox} onClick={() => { this.setState({ showSelectStore: !showSelectStore ,showSelectPostions:false,showSelectDepartment:false }) }}>{this.getSelectStoresText()}</div>
                    <div className={styles.rightBoxImg} onClick={() => { this.setState({ showSelectStore: !showSelectStore,showSelectPostions:false,showSelectDepartment:false }) }}> <img src={require('../asset/arrow_down.png')}></img> </div>
                    {showSelectStore && (<div style={{ position: 'absolute', left: 130, top: 30, zIndex: 20 }}>
                        <DropListMore lineWidth={220} select={selectstores} showLine={true} datas={stores} backgroundColor={'#181e31'}
                            selectOnClick={(item => {
                                let temp = selectstores;
                                if (temp.indexOf(item) < 0) {
                                    temp.push(item)
                                } else {
                                    let ids = temp.indexOf(item)
                                    temp.splice(ids, 1)
                                }
                                this.setState({ selectstores: temp })
                            })}
                        ></DropListMore>
                    </div>)}
                </div>


                <div className={styles.itemContainer}>
                    <div className={styles.leftText}>查阅权限:</div>
                    <div className={styles.rightBox} onClick={() => {
                        if (selectposition && selectdepartment) { this.setState({ showSelectPermission: true }) }
                        else { Toast.info('请先选择所属部门和职位') }
                    }}>{this.getSelectPermissionText()}</div>
                    <div className={styles.rightBoxImg} onClick={() => { this.setState({ showSelectPermission: true }) }}> <img src={require('../asset/arrow_down.png')}></img> </div>
                </div>
                <div className={styles.hline} ></div>

                <div className={styles.buttonContainer}>
                    <button className={styles.closebutton} onClick={() => { this.props.Close() }}>取消</button>
                    <button className={styles.savebutton} onClick={() => { this.addUser() }}>保存</button>
                </div>



                {showSelectPermission && <div style={{ position: 'absolute', top: -100, left: 0, zIndex: 30 }}>
                    <SelectPermission datas={selectpermissons} savePermission={(arr) => { this.setState({ selectpermissons: arr, showSelectPermission: false }) }} isAdd={true} position={selectposition} Close={() => { this.setState({ showSelectPermission: false }) }}></SelectPermission>
                </div>}
            </div>

        )
    }

    getStores() {
        ApiStore.getStoreList().then(res => {
            if (res.data.code == 0) {
                this.setState({ stores: res.data.data.stores })
            }
        })
    }


    getDepartment() {
        Api.getMessage().then(res => {
            if (res.data.code == 0) {
                this.setState({ department: res.data.data.info })
            }
        })
    }

    getPostions() {
        let {
            department,
            selectdepartment
        } = this.state
        if (!selectdepartment || !department) {

            return []
        }

        department.map(item => {


            if (item.name == selectdepartment.name) {

                this.setState({ position: item.position })
            }
        })


    }


    getSelectStoresText() {
        let {
            selectstores
        } = this.state
        let text = ''
        selectstores.map((item, index) => {
            if (index == 0) {
                text = item.name
            } else {
                text = text + ',' + item.name
            }
        })

        return text

    }

    getSelectPermissionText() {
        let {
            selectpermissons
        } = this.state
        let text = ''
        selectpermissons.map((item, index) => {
            if (index == 0) {
                text = item.name
            } else {
                text = text + ',' + item.name
            }
        })

        return text

    }


    addUser() {
        let {
            phone,
            name,
            department,
            stores,
            position,
            selectdepartment,
            selectposition,
            selectstores,
            selectpermissons,
            showSelectDepartment,
            showSelectPostions,
            showSelectStore,
            showSelectPermission
        } = this.state

        if (name && phone && selectdepartment && selectposition && selectstores.length > 0 && selectpermissons.length > 0) {

            let permisssion = []
            selectpermissons.map(item => {
                permisssion.push(item.value)
            })
            let poststores=[]
             selectstores.map(item=>{
                 poststores.push(item.id)
             })

            let level = 1;
            if (selectposition.name == '总经理') {

                level = 1
            } else if (selectposition.name == '副总经理' || selectposition.name == '总监') {

                level = 2
            } else {

                level = 3

            }
            let param = { name: name, mobile: phone, position: selectposition.name, store: poststores, department: selectdepartment.name, permission: permisssion,role:level }
            Api.addUser(param).then(res => {
                if (res.data.code == 0) {
                   this.props.addUser();

                }else {
                    Toast.info(res.data.message)  
                }
            })
        } else {
            Toast.info('请完善数据')
        }


    }


}

