import React, { Component } from 'react';

import str from '../../str';


import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import List from 'react-virtualized/dist/commonjs/List';

import Progress from '../../component/progress'
import DropListMore from '../../component/droplistmore'
import styles from './store.module.css'

const TimeArr = [{ name: '今天', value: 'today' }, { name: '昨天', value: 'yesterday' }, { name: '本周', value: 'week' }, { name: '本月', value: 'month' }, { name: '上月', value: 'last_month' }, { name: '今年', value: 'year' }]
//门店-门店选择
export default class StoreSlect extends Component {
    constructor(props) {
        super();
        this.state = {
            citys: [],
            showCitys: false,
            showStores: false,
            selectCitys: [],
            selectStores: []
        };

    }

    componentDidMount() {
        this.getcitys()
    }
    render() {
        let {
            citys,
            showCitys,
            showStores,
            selectCitys,
            selectStores,
        } = this.state



        return (
            <div className={styles.selectContainer}>
                <div style={{ height: 114, backgroundColor: '#4d5268', padding: 20 }}>
                    <p style={{ fontSize: 20, color: "white", marginBottom: 25 }}>门店选择</p>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: 'space-between' }}>
                        <div className={styles.flexrow}>
                            <div style={{ fontSize: 14, marginRight: 25, color: "white" }}>所在省份</div>
                            <div className={styles.flexrowbox} onClick={() => { this.setState({ showCitys: !showCitys }) }}>
                                <div style={{ width: 174 }}></div>
                                <img src={require('../../asset/arrow_down.png')} style={{ width: 9, height: 5, marginRight: 10 }}></img>
                            </div>


                            <div style={{ fontSize: 14, marginRight: 25, color: "white", marginLeft: 70 }}>区域门店</div>
                            <div className={styles.flexrowbox} onClick={() => { this.setState({ showStores: !showStores }) }}>
                                <div style={{ width: 174 }}></div>
                                <img src={require('../../asset/arrow_down.png')} style={{ width: 9, height: 5, marginRight: 10 }}></img>
                            </div>
                        </div>

                        <div>
                            <button className={styles.button_sure} style={{ marginRight: 15 }}>确定</button>
                            <button className={styles.button_clear}  onClick={()=>{this.setState({selectCitys:[],selectStores:[]})}} >清除</button>
                        </div>
                    </div>

                    {showCitys && (<div style={{ position: 'absolute', left: 100, top: 105, zIndex: 11 }}>
                        <DropListMore selectOnClick={(item) => {
                            let temp = selectCitys;
                            if (selectCitys.indexOf(item) > -1) {
                                let ids = selectCitys.indexOf(item)
                                temp.splice(ids, 1)
                            } else {
                                temp.push(item)
                            }
                            this.setState({ selectCitys: temp })
                        }} showLine={true} datas={citys} backgroundColor={'#181e31'} lineWidth={200} select={selectCitys} ></DropListMore>
                    </div>)}

                    {showStores && (<div style={{ position: 'absolute', left: 455, top: 105, zIndex: 11 }}>
                        <DropListMore selectOnClick={(item) => {
                            let temp = selectStores;
                            if (selectStores.indexOf(item) > -1) {
                                let ids = selectStores.indexOf(item)
                                temp.splice(ids, 1)
                            } else {
                                temp.push(item)
                            }
                            this.setState({ selectStores: temp })
                        }} showLine={true} datas={citys} backgroundColor={'#181e31'} lineWidth={200} select={selectStores}></DropListMore>
                    </div>)}

                </div>
            </div>

        )
    }

    getcitys() {
        let {
            citys
        } = this.state

        let temp = []
        data.map(item => {
            item.value = item.name
            temp.push(item)
        })

        this.setState({ citys: temp })
    }


}


const data = [
    { name: '黑龙江' },
    { name: '吉林' },
    { name: '辽宁' },
    { name: '内蒙古' },
    { name: '北京', },
    { name: '天津', },
    { name: '河北', },
    { name: '山东', },
    { name: '山西', },
    { name: '江苏', },
    { name: '上海', },
    { name: '浙江', },
    { name: '福建', },
    { name: '广东', },
    { name: '海南', },
    { name: '台湾', },
    { name: '香港', },
    { name: '澳门', },
    { name: '河南', },
    { name: '安徽', },
    { name: '江西', },
    { name: '广东', },
    { name: '陕西', },
    { name: '湖北', },
    { name: '湖南', },
    { name: '广西', },
    { name: '宁夏', },
    { name: '重庆', },
    { name: '贵州', },
    { name: '四川', },
    { name: '云南', },
    { name: '甘肃', },
    { name: '青海', },
    { name: '西藏', },
    { name: '新疆', }
];