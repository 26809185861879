import React, { Component } from 'react'
import PropTypes from 'prop-types'

import str from '../str'
import '../app.css'
import styles from './adduer.module.css'
import Api from '../api/user'
import ApiStore from '../api/dataover_store'
import DropList from '../component/droplist'
import DropListMore from '../component/droplistmore'

//权限  查阅板块授权
export default class SelectPermission extends Component {
    constructor(props) {
        super();
        this.state = {
            selectPermission: [],
            userlevel: 1
        };

    }

    componentDidMount() {
        let position = this.props.position;
        let isAdd = this.props.isAdd;
        if (isAdd) {
            this.setData(position);
        }else{
            this.setData(position);
        }
    }
    render() {

        let {
            selectPermission,
            userlevel
        } = this.state
        let position = this.props.position;
        let isAdd = this.props.isAdd;

        return (


            <div className={styles.container} style={{ height: 550 }}>
                <div className={styles.topContainer}> <span>查阅板块授权</span> <img src={require('../asset/close.png')} style={{ width: 20, height: 20 }} alt="" onClick={() => { this.props.Close && this.props.Close() }}></img></div>
                <div style={{ paddingTop: 20, paddingLeft: 20, paddingRight: 20, position: 'relative' }}>
                    <div>选择权限</div>

                    <div style={{ marginTop: 20, paddingLeft: 20, position: 'relative' }} >
                        <div >
                            <img style={{ marginRight: 5 }} src={require('../asset/check_Selected.png')} ></img>
                            <span>总览</span>
                        </div>

                        <div className={styles.PitemContainer}>
                            {total.map((item, index) => {
                                return (
                                    <div style={{ marginLeft: 20 }} key={index}>
                                        <img style={{ marginRight: 5, }} src={require('../asset/check_Selected.png')} ></img>
                                        <span>{item.name}</span>
                                    </div>
                                )
                            })}
                        </div>


                    </div>



                    <div style={{ marginTop: 40, paddingLeft: 20 }}>
                        <div onClick={() => {
                            let temp = selectPermission;
                            if (selectPermission.indexOf(store[1]) !== -1) {
                                let ids = selectPermission.indexOf(store[1])
                                temp.splice(ids, 1)

                            } else {
                                temp.push(store[1])
                            }
                            this.setState({ selectPermission: temp })
                        }}>
                            <img className={styles.imgindex} style={{ marginRight: 5 }} src={require('../asset/check_Selected.png')}></img>
                            <span>门店分析</span>
                        </div>

                        <div className={styles.PitemContainer}>
                            {store.map((item, index) => {
                                return (
                                    <div style={{ marginLeft: 20 }} key={index} onClick={() => {
                                        if (index > 0) {
                                            let temp = selectPermission;
                                            if (selectPermission.indexOf(store[1]) !== -1) {
                                                let ids = selectPermission.indexOf(store[1])
                                                temp.splice(ids, 1)

                                            } else {
                                                temp.push(store[1])
                                            }
                                            this.setState({ selectPermission: temp })
                                        }
                                    }}>
                                        <img style={{ marginRight: 5 }} src={selectPermission.indexOf(item) != -1 ? require('../asset/check_Selected.png') : require('../asset/check_Defalt.png')}></img>
                                        <span>{item.name}</span>
                                    </div>
                                )
                            })}
                        </div>


                    </div>



                    <div style={{ marginTop: 40, paddingLeft: 20 }} className={styles.imgindex}>
                        <div className={styles.imgindex} onClick={()=>{this.tireAll()}} >
                            <img style={{ marginRight: 5 }} src={this.tireIsSelect()? require('../asset/check_Selected.png'):require('../asset/check_Defalt.png')}></img>
                            <span>轮胎分析</span>
                        </div>

                        <div className={styles.PitemContainer}>
                            {tire.map((item, index) => {
                                return (
                                    <div style={{ marginLeft: 20 }} key={index} onClick={()=>{
                                        let temp = selectPermission;
                                        if (selectPermission.indexOf(item) !== -1) {
                                            let ids = selectPermission.indexOf(item)
                                            temp.splice(ids, 1)

                                        } else {
                                            temp.push(item)
                                        }
                                        this.setState({ selectPermission: temp }) 
                                    }}>
                                        <img style={{ marginRight: 5 }} src={selectPermission.indexOf(item) > 0 ? require('../asset/check_Selected.png') : require('../asset/check_Defalt.png')}></img>
                                        <span>{item.name}</span>
                                    </div>
                                )
                            })}
                        </div>


                    </div>


                </div>

                <div className={styles.hline} ></div>

                <div className={styles.buttonContainer}>
                    <button className={styles.closebutton} onClick={() => { this.props.Close() }}>取消</button>
                    <button className={styles.savebutton} onClick={() => {
                        
                            this.props.savePermission(selectPermission)
                        
                    }} >保存</button>
                </div>

            </div>

        )
    }


    //账号分3级 新增用户初始化数据
    setData(position) {
        let select = []
        let level = 1
        let All=total.concat(tire,store)
        if (position.name == '总经理') {
            select = total.concat(store, tire)
            level = 1
        } else if (position.name == '副总经理' || position.name == '总监') {
            select = total.concat(store)
            level = 2
        } else {
            select = select.concat(total)
            select.push(store[0])
            level = 3

        }

        //如果已经选过了再进来 显示已经选过的数据
        if (this.props.datas && this.props.datas.length > 0) {
            select=[] 
            this.props.datas.map(item=>{
                All.map(data=>{
                   if(item.value==data.value){
                       select.push(data)
                   }
                })

            })
        }

        this.setState({ selectPermission: select, userlevel: level })

    }
   
    

    tireAll(){
        let {
            selectPermission
        }=this.state
        let temp=selectPermission 
        if(this.tireIsSelect()){
            
            tire.map(item=>{
               if(temp.indexOf(item)>-1){
                  let ids=  temp.indexOf(item)
                  temp.splice(ids,1)
               }  
            })

      }  else {
         temp=temp.concat(tire)
      }   
      
      this.setState({selectPermission:temp})
    }

    tireIsSelect(){
       let {
           selectPermission
       }=this.state 
       let flag=false
        tire.map(item=>{
             if(selectPermission.indexOf(item)>-1){
                 flag= true
             }
        })
        
        return flag
    }

}

const total = [{ name: '实时数据', value: 1001 }, { name: '设备分布', value: 1002 }, { name: '数据排行榜', value: 1003 }, { name: '累计数据', value: 1004 }]
const store = [{ name: '单店分析', value: 1005 }, { name: '多店分析', value: 1006 }]
const tire = [{ name: '轮胎机会', value: 1007 }, { name: '轮胎市场', value: 1008 }, { name: '客户画像', value: 1009 }]
