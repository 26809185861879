import React, { Component } from 'react'
import PropTypes from 'prop-types'

import str from '../str'
import '../app.css'
import styles from './adduer.module.css'
import Api from '../api/user'
import ApiStore from '../api/dataover_store'
import DropList from '../component/droplist'
import DropListMore from '../component/droplistmore'
import SelectPermission from '../permission/selectpermission'
import Toast from '../component/Toast/index'

//添加1级用户、
export default class addUser extends Component {
    constructor(props) {
        super();
        this.state = {
            phone: '',
            name: '',
            merchant: null,
            merchants: [],
            showSelectMerchants: false
        };

    }

    componentDidMount() {
        this.getData()

    }
    render() {

        let {
            phone,
            name,
            merchant,
            merchants,
            showSelectMerchants
        } = this.state

        return (

            <div className={styles.container}>
                <div className={styles.topContainer}> <span>添加成员</span> <img src={require('../asset/close.png')} style={{ width: 20, height: 20 }} alt="" onClick={() => { this.props.Close() }}></img></div>

                <div className={styles.itemContainer}>
                    <div className={styles.leftText}>手机号码:</div>
                    <input className={styles.input} value={phone} onChange={event => this.setState({ phone: event.target.value })}></input>
                </div>


                <div className={styles.itemContainer}>
                    <div className={styles.leftText}>{'姓名:'}</div>
                    <input className={styles.input} value={name} onChange={event => this.setState({ name: event.target.value })}></input>
                </div>






                <div className={styles.itemContainer}>
                    <div className={styles.leftText}>选择商户:</div>
                    <div className={styles.rightBox} onClick={() => { this.setState({ showSelectMerchants: !showSelectMerchants }) }} >{merchant ? merchant.name : ''}</div>
                    <div className={styles.rightBoxImg} onClick={() => { this.setState({ showSelectMerchants: !showSelectMerchants }) }}> <img src={require('../asset/arrow_down.png')}></img> </div>

                    {showSelectMerchants && <div  className={styles.firstContainer} style={{ position: 'absolute', left: 130, top: 30, zIndex: 20 }}>
                        <DropList lineWidth={220} height={120}  select={merchant} showLine={true} datas={merchants} backgroundColor={'#181e31'}
                            selectOnClick={(item => { this.setState({ showSelectMerchants: false, merchant: item }) })}
                        ></DropList>
                    </div>}
                </div>




                <div className={styles.hline} ></div>

                <div className={styles.buttonContainer}>
                    <button className={styles.closebutton} onClick={() => { this.props.Close() }}>取消</button>
                    <button className={styles.savebutton} onClick={() => { this.addUser() }}>保存</button>
                </div>




            </div>

        )
    }


    getData() {
        Api.getmerchants().then(res => {
            if (res.data.code == 0) {
                let list = res.data.data.merchants;
                this.setState({ merchants: list })
            }
        })
    }




    addUser() {
        let {
            phone,
            name,
            merchant
        } = this.state

        if (name && phone && merchant) {

         
            let param = { name: name, mobile: phone,merchant:merchant.id }
            Api.addUserFirst(param).then(res => {
                if (res.data.code == 0) {
                    this.props.addUser();
                }else {
                    Toast.info(res.data.message) 
                }
            })
        } else {
            Toast.info('请完善数据')
        }


    }


}

