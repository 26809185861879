
import axios from './settingtoken';


export default {
    /**
     * 获取用户信息
     * @param {*} params 
     */
    getUserData() {
        return axios.get(`/api/user/current/`)
    },

    //获取用户列表
    getUserList() {
        return axios.get(`/api/user/list/`)
    },

    //添加用户
    addUser(params) {
        return axios.post(`/api/user/add/`, params)
    },

    //添加用户1级
    addUserFirst(params) {
        return axios.post(`/api/user/add/first/`, params)
    },

    //编辑用户
    editUser(params) {
        return axios.put(`/api/user/update/`, params)
    },


    //删除用户
    deleteUser(params) {
        return axios.delete(`/api/user/remove/`, params)
    },

    //批量删除用户
    deleteUsers(params) {
        return axios.delete(`/api/user/batch_remove/`, params)
    },

    //获取部门和职位信息
    getMessage() {
        return axios.get(`/api/user/position/`)
    },


    //获取权限信息
    getUserPermission() {
        return axios.get(`/api/user/permissions/`)
    },


    getmerchants() {
        return axios.get('/api/user/merchants/')
    },

    //获取修改密码验证码
    getUpdatePasswordCode(params) {
        return axios.get(`/api/user/modify/sms/`, { params: params })
    },

    //修改密码
    UpdatePassword(params) {
        return axios.post(`/api/user/update/password/`, params)
    },
}