import React, {Component} from 'react';
import PropTypes from 'prop-types';
export default class Progress extends Component {

  constructor(props) {
    super(props)
  }
 
  render() {
    let percentageNum =this.props.percentageNum;
    //这个支持css样式响应式的
    let width=this.props.width
    let height=this.props.height
    let secondColor=this.props.secondColor
    let background=this.props.background
    let showLine=this.props.showLine
    //不支持样式响应式,可以写死
    // let leftPercentage = (1-this.props.percentageNum)*(-450);
    let div1 = {
      //不支持样式响应式,可以写死
      // width:"450px"
      //这个支持css样式响应式的
        width: width? width:"100%",
        height:height,
        background:background,
        position: "relative",
        minWidth:width?width* 1350/1920: 0,
        overflow: "hidden",
      };
    let div2 = {
      //不支持样式响应式,可以写死
      // width:"450px"
      //这个支持css样式响应式的
        width:`${percentageNum}%`, 
        height:height,
        background:secondColor,
        position: "absolute",
      //不支持样式响应式,可以写死
        // left:`${leftPercentage}px`,
      //这个支持css样式响应式的
        left:`${0}%`,
      
      };

    return (
      <div style={div1}>
        <div style={div2}></div> 
     
      </div>
    )
  }
}
Progress.propTypes = {
  // 磨损状态数组，左前、右前、左后、右后
 height:PropTypes.number,
 secondColor:PropTypes.string,
 background:PropTypes.string
}

Progress.defaultProps={
 height:18,
 secondColor:'#00e3da',
 background:'#373d50'
}