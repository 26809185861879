import React, { Component } from 'react';

import str from '../../str';
import styles from './store.module.css'
import TimeDropList from '../../component/droplist'
import echarts from 'echarts/lib/echarts'
//导入饼图
import 'echarts/lib/chart/pie'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/title'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/markPoint'
import ReactEcharts from 'echarts-for-react'
import Api from '../../api/dataover_store'

//门店分析-业务属性分析
export default class Attribute extends Component {
    constructor(props) {
        super();
        this.state = {
            selectTime: TimeArr[0],
            showTimeDrop: false,
            cicleDatas: [],
            type: str.Attribute_FindChance,
            barDatas: [],
            chance_total: 0,
            tire_sale_number: 0,
            tire_service_number: 0
        };

    }

    //props发生变化时触发
    componentWillReceiveProps(props) {

        if (props.store !== this.props.store) {
            this.getCicleData(props.store)
            this.getBarData(props.store)
        }
    }



    componentDidMount() {



        this.getCicleData(this.props.store)
        this.getBarData(this.props.store)


    }
    render() {
        let {
            selectTime,
            showTimeDrop,
            circleOption,
            type,
            chance_total,
            tire_sale_number,
            tire_service_number
        } = this.state


        return (
            <div className={styles.attribute_container}>
                <div className={styles.attribute_top}>
                    <div className={styles.flex_row} >
                        <div style={{ height: 30, width: 2, backgroundColor: '#00c9b2' }}> </div>
                        <div style={{ fontSize: 16, marginLeft: 10, color: "white" }} >业务属性分析</div>
                    </div>

                    <div style={{ position: 'relative' }}>
                        <div style={{ fontSize: 16, color: "white" }} onClick={() => { this.setState({ showTimeDrop: !showTimeDrop }) }}>{selectTime.name} <img alt='' src={require('../../asset/jiantou_down.png')} ></img> </div>
                        {showTimeDrop && (<div style={{ position: 'absolute', top: 30, right: 0 }}>
                            <TimeDropList select={selectTime} backgroundColor={'#181e31'} showLine={true} datas={TimeArr} selectOnClick={(item) => {
                                this.setState({ selectTime: item, showTimeDrop: false }, () => {
                                    this.getCicleData(this.props.store)
                                    this.getBarData(this.props.store)
                                })
                            }}></TimeDropList>
                        </div>)}
                    </div>

                </div>
                <div style={{ display: "flex", flexDirection: 'row', marginTop: 20 }}>
                    <div className={styles.minwidth425}  >
                        <div style={{ display: "flex", flexDirection: 'row', }} ><img src={require('../../asset/Proportion.png')} style={{ width: 20, height: 20, marginLeft: 15, marginRight: 5, marginTop: 3 }}></img>  <div style={{ color: "white", fontSize: 16, marginLeft: 10 }}>服务类型占比</div> </div>
                        <div style={{ marginTop: 60, paddingTop: 20 }}>
                            <ReactEcharts option={this.getCicleOption()} />
                        </div>
                    </div>



                    <div className={styles.minwidth941} style={{ display: 'flex', flexDirection: 'column', }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div ><img src={require('../../asset/Quantity.png')} style={{ width: 24, height: 18, marginLeft: 15, marginBottom: 7 }}></img>  <span style={{ color: "white", fontSize: 16, marginLeft: 10 }}>服务类型剖析</span> </div>


                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={{ width: 72, display: "flex", flexDirection: 'column', alignItems: 'center' }} onClick={() => { this.setState({ type: str.Attribute_Transfor }, () => { this.getBarData(this.props.store) }) }}>
                                    <div style={{ fontSize: 16, color: "white", marginBottom: 5 }}>转化数</div>
                                    {type == str.Attribute_Transfor && <div className={styles.lineh64}></div>}
                                </div>

                                <div style={{ width: 72, display: "flex", flexDirection: 'column', alignItems: 'center' }} onClick={() => { this.setState({ type: str.Attribute_Track100 }, () => { this.getBarData(this.props.store) }) }}>
                                    <div style={{ fontSize: 16, color: "white", marginBottom: 5 }}>跟踪%</div>
                                    {type == str.Attribute_Track100 && <div className={styles.lineh64}></div>}
                                </div>
                                <div style={{ width: 72, display: "flex", flexDirection: 'column', alignItems: 'center' }} onClick={() => { this.setState({ type: str.Attribute_FindChance }, () => { this.getBarData(this.props.store) }) }}>
                                    <div style={{ fontSize: 16, color: "white", marginBottom: 5 }}>发现机会</div>
                                    {type == str.Attribute_FindChance && <div className={styles.lineh64}></div>}
                                </div>
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: "center", marginTop: 28 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <div style={{ color: 'white', fontSize: 20 }}>{chance_total}<span style={{ fontSize: 16 }}>辆</span></div>
                                <div style={{ color: 'white' }}>机会总数</div>
                            </div>
                            {type !== str.Attribute_FindChance && (<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: 100, marginRight: 100 }}>
                                <div style={{ color: 'white', fontSize: 20 }}>{tire_sale_number}<span style={{ fontSize: 16 }}>条</span></div>
                                <div style={{ color: 'white' }}>轮胎销售</div>
                            </div>)}
                            {type !== str.Attribute_FindChance && (<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <div style={{ color: 'white', fontSize: 20 }}>{tire_service_number}<span style={{ fontSize: 16 }}>辆</span></div>
                                <div style={{ color: 'white' }}>轮胎服务</div>
                            </div>)}

                        </div>

                        <div style={{ marginTop: 20 }}>
                            <ReactEcharts option={this.getBarOption()} />
                        </div>

                    </div>

                </div>

            </div>
        )
    }





    getBarOption() {
        let {
            barDatas,
            cicleDatas
        } = this.state

        let xdatas = [];
        let datas = [];
        barDatas.map(item => {
            xdatas.push(item.name)
            datas.push(item.value)
        })
        let option = {
            color: ['#3398DB'],
            tooltip: {
                trigger: 'axis',
                axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                    type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                }
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: [
                {
                    type: 'category',
                    data: xdatas,
                    axisTick: {
                        alignWithLabel: true,

                    },
                    axisLabel: {
                        show: true,
                        textStyle: {
                            color: '#fff'
                        }
                    }
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    axisLabel: {
                        show: true,
                        textStyle: {
                            color: '#fff'
                        }
                    }
                }
            ],
            series: [
                {
                    name: '数量',
                    type: 'bar',
                    barWidth: '40%',
                    data: datas,
                    itemStyle: {
                        normal: {
                            color: function (params) {
                                let colorList = ['#00d2ce', '#2075f1', '#3cc3f5', '#e18832', '#9e0ee2'];

                                return colorList[params.dataIndex]

                            }
                        }
                    }
                }
            ]
        };

        return option
    }


    //获得饼状图数据
    getCicleOption() {

        let {
            cicleDatas
        } = this.state


        let names = []
        let total = 0
        cicleDatas.map(item => {
            total = total + item.value
        })

        cicleDatas.map(item => {
            item.bfb = this.calculate100(item.value, total)
        })


        let option = {
            title: {

                text: '进店车次\n' + total,
                left: '39%',
                y: 'center',
                textStyle: {
                    color: '#fff'
                },
                textAlign: 'center'

            },
            emphasis: {
                show: false,
                formatter: '{d}%'
            },
            tooltip: {
                trigger: 'item',
                //提示框浮层内容格式器，支持字符串模板和回调函数形式。
                formatter: "{a} <br/>{b} : {c} ({d}%)"
            },
            legend: {
                orient: 'vertical',
                top: 20,
                right: 10,
                data: cicleDatas,
                textStyle: {
                    color: '#fff'
                },
                formatter: function (name) {
                    let str = ''
                    cicleDatas.map(item => {
                        if (item.name == name) {
                            str = item.bfb + '%'
                        }
                    })
                    return str + name;
                }

            },
            color: ['#00d2ce', '#2075f1', '#3cc3f5', '#e18832', '#9e0ee2'],
            series: [
                {
                    radius: [45, 135],
                    name: '百分比',
                    type: 'pie',
                    data: cicleDatas,
                    roseType: 'radius',
                    center: ['40%', '50%'],
                    label: {
                        show: false
                    },

                    emphasis: {
                        label: {
                            show: false
                        }
                    },
                }
            ]
        }

        return option
    }


    calculate100(i, j) {

        if (i > 0 && j > 0) {

            return parseInt((i / j) * 100);
        }
        return 0;

    }


    getCicleData(store) {
        let store_id = ''

        if (store) {
            if (store instanceof Array) {

                store.map((item, index) => {
                    if (index == 0) {
                        store_id = store_id + item.id
                    } else {
                        store_id = store_id + ',' + item.id
                    }
                })

            } else {


                store_id = store.id
            }
        }


        Api.getServicePieData({ time: this.state.selectTime.value, store_id: store_id }).then(res => {
            if (res.data.code == 0) {
                this.setState({ cicleDatas: res.data.data.services })
            } else {

            }
        })


    }

    getBarData(store) {

        let store_id = ''

        if (store) {
            if (store instanceof Array) {


                store.map((item, index) => {
                    if (index == 0) {
                        store_id = store_id + item.id
                    } else {
                        store_id = store_id + ',' + item.id
                    }
                })

            } else {


                store_id = store.id
            }
        }

        let {
            type
        } = this.state

        Api.getServiceTypeData({ time: this.state.selectTime.value, store_id: store_id, keyword: type }).then(res => {
            if (res.data.code == 0) {
                let data = res.data.data
                this.setState({ barDatas: res.data.data.services, chance_total: data.chance_total, tire_sale_number: data.tire_sale_number, tire_service_number: data.tire_service_number })

            } else {

            }
        })
    }

}

const TimeArr = [{ name: '本周', value: 'week' }, { name: '本月', value: 'month' }, { name: '今年', value: 'year' }]

