import React, { Component } from 'react';
import LoginComponent from '../../login/loginComponent'
import str from '../../str';
import styles from './total.module.css'
import RealData from './total_realdata'
import DeviceData from './total_device'
import TotalData from './total_total'
import DataSort from './total_datasort'

//总览
export default class Total extends Component {
    constructor(props) {
      super();
      this.state = {
      
      };
  
    }
  
    componentDidMount() {
   

    }
    render() {
    let{
        
    }=this.state
  
   
      return (
        <div className={styles.container}>
           <div className={styles.flex_row}>
           <RealData></RealData>
           <DeviceData></DeviceData>
           <DataSort></DataSort>
           </div>

           <TotalData></TotalData>
        </div>
      )
    }
  

  
  
  }
  


