import React, { Component } from 'react';

import str from '../../str';
import styles from './tire.module.css'
import TimeDropList from '../../component/droplist'
import echarts from 'echarts/lib/echarts'
//导入饼图
import 'echarts/lib/chart/pie'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/title'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/markPoint'
import ReactEcharts from 'echarts-for-react'
import DataProgress from '../../component/progress'
import Api from '../../api/dataover_tire'

//轮胎分析-客户画像分析
export default class TireChance extends Component {
    constructor(props) {
        super();
        this.state = {
            chanceTypeOption: '',
            datas: [{ name: '严重磨损', value: 0 }, { name: '轻微磨损', value: 0 }],
            total: 0
        };

    }

    componentDidMount() {
        this.getData()
    }
    render() {
        let {
            datas,
            total
        } = this.state


        return (



            <div style={{    minWidth: 522, padding: 20 }}>
                <div style={{ display: "flex", flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div className={styles.flex_row} >
                        <div style={{ height: 30, width: 2, backgroundColor: '#00c9b2' }}> </div>
                        <div style={{ fontSize: 16, marginLeft: 10, color: "white" }} >客户画像分析</div>
                    </div>

                   {/* <div className={styles.flex_row} >
                        <img src={require('../../asset/dataover/position.png')} style={{ width: 28, height: 22 }}></img>
                        <div style={{ fontSize: 18, marginLeft: 5, color: "white" }} >上海</div>
                    </div>*/} 
                </div>

                <div className={styles.flex_row} style={{ marginTop: 20, paddingLeft: 20 }}>
                    <img src={require('../../asset/Proportion.png')} style={{ width: 24, height: 24 }}></img>
                    <div style={{ fontSize: 16, marginLeft: 10, color: "white" }} >轮胎转化占比</div>
                </div>



                <div style={{ paddingLeft: 20, paddingRight: 20, marginTop: 40 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '10px' }}>
                        <div style={{color:'white'}}>{datas[0].name}</div>
                        <div style={{color:'white'}}>{datas[1].name}</div>
                    </div>

                    <DataProgress height={40} secondColor={'#00c9b2'} background={'#0b72d8'} showLine={true} percentageNum={this.calculate100(datas[0].value, total)}></DataProgress>

                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10, }}>
                        <div style={{color:'#00c9b2',fontSize:20}}>{this.calculate100(datas[0].value, total)}<span style={{fontSize:16}}>%</span></div>
                        <div style={{color:'#0b72d8',fontSize:20}}>{this.calculate100(datas[1].value, total)}<span style={{fontSize:16}}>%</span></div>
                    </div>



                </div>


                <div style={{ marginTop: 50 }}>

                    <div className={styles.flex_row} style={{ marginTop: 20, paddingLeft: 20 }}>
                        <img src={require('../../asset/Quantity.png')} style={{ width: 28, height: 22 }}></img>
                        <div style={{ fontSize: 16, marginLeft: 10, color: "white" }} >转化类型分析</div>
                    </div>


                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 30 }}>
                        <div style={{color:"white"}}><span>{total}</span>条</div>
                        <div style={{color:"white"}}>当月销售轮胎</div>
                    </div>
                    <ReactEcharts option={this.getUseBarOption()} style={{ height: 360 }} />
                </div>
            </div>


        )
    }


    getUseBarOption() {
        let {
            datas,
        } = this.state


        let xdatas = [];
        let datas1 = [];
        datas.map(item => {
            xdatas.push(item.name)
            datas1.push(item.value)
        })
        let option = {
            color: ['#3398DB'],
            tooltip: {
                trigger: 'axis',
                axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                    type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                }
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: [
                {
                    type: 'category',
                    data: xdatas,
                    axisTick: {
                        alignWithLabel: true,

                    },
                    axisLabel: {
                        show: true,
                        textStyle: {
                            color: '#fff'
                        }
                    }
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    axisLabel: {
                        show: true,
                        textStyle: {
                            color: '#fff'
                        }
                    }
                }
            ],
            series: [
                {
                    name: '数量',
                    type: 'bar',
                    barWidth: '40%',
                    data: datas1,
                    itemStyle: {
                        normal: {
                            color: function (params) {
                                let colorList = ['#00c9b2', '#0b72d8'];

                                return colorList[params.dataIndex]

                            }
                        }
                    }
                }
            ]
        };

        return option
    }


    getData() {
        Api.getTransformed().then(res => {
            if (res.data.code == 0) {
                let total = 0
                res.data.data.data_list.map(item => {
                    total = total + item.value
                })
                this.setState({ datas: res.data.data.data_list, total: total })
            } else {

            }
        })
    }


    calculate100(i, j) {
     
        if (i > 0 && j > 0) {

            return parseInt((i / j) * 100);
        }
        return 0;

    }

}

const TimeArr = [{ name: '本周', value: 'week' }, { name: '本月', value: 'month' }, { name: '今年', value: 'year' }]

const DataArr = [{ name: '轮胎磨损', value: 100 }, { name: '轮胎偏磨', value: 60 }, { name: '轮胎换位', value: 76 },
{ name: '轮胎外伤', value: 23 }, { name: '轮胎内外倒置', value: 14 }, { name: '轮胎使用年限', value: 48 }]

const TireUserTime = [{ name: '<3个月', value: 100 }, { name: '<6个月', value: 60 }, { name: '<12个月', value: 76 },
]