import React, { Component } from 'react';

import str from '../../str';
import styles from './total.module.css'

import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import List from 'react-virtualized/dist/commonjs/List';

import Progress from '../../component/progress'
import TimeDropList from '../../component/droplist'
import Api from '../../api/dataover'
import Util from '../../Util'
const TimeArr = [{ name: '今天', value: 'today' }, { name: '昨天', value: 'yesterday' }, { name: '本周', value: 'week' }, { name: '本月', value: 'month' }, { name: '上月', value: 'last_month' }, { name: '今年', value: 'year' }]
//总览-数据排行
export default class Total_SortData extends Component {
    constructor(props) {
        super();
        this.state = {
            type: str.Check_Sort,
            datas: [],
            showTimeSelect: false,
            selectTime: TimeArr[0]
        };

    }

    componentDidMount() {
        this.getData();
        this.circle = setInterval(() => {
            this.getData();
        }, 1000*30);
    }


    componentWillUnmount() {

        clearInterval(this.circle)
        this.setState = (state, callback) => {
            return;
        };

    }
    render() {
        let {
            type,
            selectTime,
            showTimeSelect,
            datas,
        } = this.state
        const styleltest = {
            bar: {
                height: 50,
            }
        }


        let timebottom = 0 - 36 * TimeArr.length

        return (
            <div className={styles.SortContainer}>
                <div className={styles.ListItemTop}>
                    <div className={styles.flex_row} >
                        <div style={{ height: 30, width: 2, backgroundColor: '#00c9b2' }}> </div>
                        <div style={{ fontSize: 16, marginLeft: 10, color: "white" }} >数据排行榜Top10</div>
                    </div>

                    <div onClick={() => { this.setState({ showTimeSelect: !showTimeSelect }) }} style={{ fontSize: 16, color: "white" }}>{selectTime.name} <img alt='' src={require('../../asset/jiantou_down.png')}></img> </div>
                    {showTimeSelect && (<div style={{ zIndex: 30, position: 'absolute', right: 0, bottom: timebottom }}>
                        <TimeDropList backgroundColor={'#181e31'} showLine={true} datas={TimeArr} selectOnClick={(item) => {
                            this.setState({ selectTime: item, showTimeSelect: false }, () => {
                                this.getData()
                            })
                        }} select={selectTime} ></TimeDropList>
                    </div>)}
                </div>

                <div className={styles.sortHeader}>
                    <div className={styles.flex_row}>
                        <img src={require('../../asset/dataover/position.png')} style={{ width: 24, height: 24 }}></img>
                        <div style={{ color: "white", marginLeft: 10, fontSize: 16 }}>全国</div>
                    </div>


                    <div className={styles.sortHeaderRight}>
                        <div className={styles.sortHEaderRightItem} onClick={() => { this.setState({ type: str.TireSale }, () => { this.getData() }) }}>
                            <div style={{ fontSize: 14, color: "white", marginBottom: 5 }}>轮胎销售</div>
                            {type == str.TireSale && <div className={styles.lineh72}></div>}
                        </div>

                        <div className={styles.sortHEaderRightItem} onClick={() => { this.setState({ type: str.Track100 }, () => { this.getData() }) }}>
                            <div style={{ fontSize: 14, color: "white", marginBottom: 5 }}>跟踪%</div>
                            {type == str.Track100 && <div className={styles.lineh72}></div>}
                        </div>

                        <div className={styles.sortHEaderRightItem} onClick={() => { this.setState({ type: str.Check_Sort }, () => { this.getData() }) }}>
                            <div style={{ fontSize: 14, color: "white", marginBottom: 5 }}>检测量</div>
                            {type == str.Check_Sort && <div className={styles.lineh72}></div>}
                        </div>
                    </div>
                </div>

                {datas.map((item, index) => {
                    return (
                        <div key={index} style={{ marginTop: 20 }}>
                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5 }}>
                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                                    <img src={require('../../asset/dataover/Nav_Stores_Selected.png')} style={{ width: 24, height: 24 }}></img>
                                    <div  className='singleline' style={{ color: "white", fontSize: 16,marginLeft:10 }}>{item.name}</div>
                                </div>
                             {type==str.Check_Sort&&(<div style={{ color: "white", fontSize: 16 }}>{item.count+ '辆('+ Util.calculate100(item.count, this.total)+'%)'  }</div>)}  
                             {type==str.Track100&&(<div style={{ color: "white", fontSize: 16 }}>{item.count+ '辆('+ Util.calculate100(item.count, item.device_number)+'%)'  }</div>)}   
                             {type==str.TireSale&&(<div style={{ color: "white", fontSize: 16 }}>{item.count+ '条('+ Util.calculate100(item.count, this.total)+'%)'  }</div>)}    
                            </div>
                          {type==str.Check_Sort&&(<Progress percentageNum={Util.calculate100(item.count, this.total)} ></Progress>)}  
                          {type==str.Track100&&(<Progress percentageNum={Util.calculate100(item.count, item.device_number)} ></Progress>)}  
                          {type==str.TireSale&&(<Progress percentageNum={Util.calculate100(item.count, this.total)} ></Progress>)}  
                        </div>
                    )
                })}

                {datas.length <= 0 && this.getNoDataView()}

            </div>
        )
    }

    getData() {
        this.total = 0

        let {
            type,
            selectTime,

        } = this.state
        let params = { time: selectTime.value, keyword: type }
        Api.getLocalTopTen(params).then(res => {
            if (res.data.code == 0) {
                let datas = res.data.data.data_list;
                var max;
                if(type==str.Track100){

                    for (var i = 0; i < datas.length; i++) {

                        //外层循环一次，就拿arr[i] 和 内层循环arr.legend次的 arr[j] 做对比
                      
                        for (var j = i; j < datas.length; j++) {
                            if ( Util.calculate100(datas[i].count,datas[i].device_number)  <  Util.calculate100(datas[j].count,datas[j].device_number)) {
                                //如果arr[j]大就把此时的值赋值给最大值变量max
                                max = datas[j];
                                datas[j] = datas[i];
                                datas[i] = max;
                            }
                        }
                    }

                }else {
                    for (var i = 0; i < datas.length; i++) {

                        //外层循环一次，就拿arr[i] 和 内层循环arr.legend次的 arr[j] 做对比
                      
                        for (var j = i; j < datas.length; j++) {
                            if (datas[i].count < datas[j].count) {
                                //如果arr[j]大就把此时的值赋值给最大值变量max
                                max = datas[j];
                                datas[j] = datas[i];
                                datas[i] = max;
                            }
                        }
                    }
                }
             
                this.total=res.data.data.total
                this.setState({ datas: datas })


            }
        })
    }


    getNoDataView() {
        return (
            <div style={{ textAlign: 'center', marginTop: 300, fontSize: 18 }}>没有数据</div>
        )
    }

}


