
import axios from './settingtoken';


export default {
    /**
     * 获取到店目的占比数据
     * @param {*} params 
     */
    getServicePieData(params) {
        return axios.get(`/api/store/service_pie/`,{params:params})
    },

    //服务类型剖析
    getServiceTypeData(params) {
        return axios.get(`/api/store/service_columnar/`,{params:params})
    },


    //轮胎规格排行TOP10
    getSpecTopTen(params) {
        return axios.get(`/api/store/spec/top10/`,{params:params})
    },

    //转化失败原因占比
    getTransforFailData(params) {
        return axios.get(`/api/store/transformed/fail/`,{params:params})
    },

    //明细排行榜
    getRank(params) {
        return axios.get(`/api/store/transformed/fail_details/`,{params:params})
    },

    //执行力排行榜
      getDoRank(params) {
        return axios.get(`/api/store/chance/top10/`,{params:params})
    },

    //获取门店列表
    getStoreList(){
        return axios.get(`/api/stores/`)
    }

}