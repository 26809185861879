import React from 'react'
import {Card, Spin, Icon} from 'antd'

class UiSpin extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div style={{zIndex:100}}>
       
          <Spin size={"large"}></Spin>
        
      </div>
    )

  }

}

export default UiSpin;