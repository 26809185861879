
/** 一些字符串 */
export default {

    //存储数据的一些key
    TokenKey: 'token',
    UserDataKey: 'Userdata',
    UserName: 'username',
    UserPassword: 'userpassword',



    LoginPage: 'Login',
    DataOVer: 'index',

    PassWord: 'password', //密码登录方式
    Code: 'code',  //短信验证登录方式

    Login: 'login',//
    PassWordFind: 'passwordfind',//找回密码
 

    //数据总览3个大类
    All: 'all',
    Store: 'store',
    Tire: 'tire',
    Permission:'permission',


    Check: 'check',//检测数据
    Transfor: 'transfor',//转化数据



    //总览-设备
    Area:'area',  //区域分布
    Active:'active', //活跃程度


    TireSale: 'sale',
    Track100: 'track',
   
    Check_Sort: 'device',//数据排序下的检测数据排序


    SingleStore: 'singlestore',//单店分析
    MoreStore: 'morestore',//多店分析
    AllStore: 'allstore',//所有店一起分析

    //门店分析-服务类型剖析
    Attribute_FindChance: 'chance',//发现机会
    Attribute_Track100: 'track',//
    Attribute_Transfor: 'transformed',

    //门店分析-轮胎规格排行
    Spec_Chance: 'chance',
    Spec_Sale: 'sale',

    //门店分析-执行力排行
    Force_Service: 'service',
    Force_Sale: 'sale',
    Force_Track: 'track',
    Force_Check: 'device',
}