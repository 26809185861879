import React, { Component } from 'react';

import str from '../../str';
import styles from '../Total/total.module.css'

import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import List from 'react-virtualized/dist/commonjs/List';

import Progress from '../../component/progress'
import TimeDropList from '../../component/droplist'
import storestyles from './store.module.css'
import Api from '../../api/dataover_store'
import util from '../../Util'


const TimeArr = [{ name: '本周', value: 'week' }, { name: '本月', value: 'month' }, { name: '今年', value: 'year' }]
//门店-轮胎规格排行榜
export default class SpecSort extends Component {
    constructor(props) {
        super();
        this.state = {
            type: str.Spec_Chance,
            datas: [],
            showTimeSelect: false,
            selectTime: TimeArr[0],
            total: 0
        };

    }


    //props发生变化时触发
    componentWillReceiveProps(props) {
        console.log(props)
        if (props.store !== this.props.store) {
            this.getData(props.store)
        }
    }

    componentDidMount() {
        this.getData(this.props.store)

    }
    render() {
        let {
            type,
            selectTime,
            showTimeSelect,
            total,
            datas,
        } = this.state


        let timebottom = 0 - 36 * TimeArr.length

        return (
            <div className={storestyles.SpecSortContainer}>
                <div className={styles.ListItemTop}>
                    <div className={styles.flex_row} >
                        <div style={{ height: 30, width: 2, backgroundColor: '#00c9b2' }}> </div>
                        <div style={{ fontSize: 16, marginLeft: 10, color: "white" }} >轮胎规格排行榜Top10</div>
                    </div>

                    <div onClick={() => { this.setState({ showTimeSelect: !showTimeSelect }) }} style={{ fontSize: 18, color: "white" }}>{selectTime.name}  <img alt='' src={require('../../asset/jiantou_down.png')} ></img> </div>
                    {showTimeSelect && (<div style={{ zIndex: 100, position: 'absolute', right: 0, bottom: timebottom }}>
                        <TimeDropList backgroundColor={'#181e31'} showLine={true} datas={TimeArr} selectOnClick={(item) => {
                            this.setState({ selectTime: item, showTimeSelect: false }, () => { this.getData(this.props.store) });
                            this.props.selectTime && this.props.selectTime(item)
                        }} select={selectTime} ></TimeDropList>
                    </div>)}
                </div>

                <div style={{ display: "flex", flexDirection: "row", justifyContent: 'flex-end', alignItems: 'center', marginTop: 10 }}>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} onClick={() => { this.setState({ type: str.Spec_Sale }, () => { this.getData(this.props.store) }) }}>
                        <div style={{ fontSize: 16, color: "white", marginBottom: 5 }}>成功销售</div>
                        {type == str.Spec_Sale && <div className={styles.lineh72}></div>}
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: 20 }} onClick={() => { this.setState({ type: str.Spec_Chance }, () => { this.getData(this.props.store) }) }}>
                        <div style={{ fontSize: 16, color: "white", marginBottom: 5 }}>发现机会</div>
                        {type == str.Spec_Chance && <div className={styles.lineh72}></div>}
                    </div>

                </div>

                {datas.map((item, index) => {
                    return (
                        <div key={index} style={{ marginTop: 20 }}>
                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5 }}>
                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                                    <img src={require('../../asset/spec.png')} style={{ width: 24, height: 24 }}></img>
                                    <div style={{ color: "white", fontSize: 16 ,marginLeft:10}}>{item.spec}</div>
                                </div>
                                <div style={{ color: "white", fontSize: 16 }}>{item.count + '条('+ util.calculate100(item.count, total) + '%)'}</div>
                            </div>
                            <Progress percentageNum={util.calculate100(item.count, total)} height={16} secondColor={'#1e95e6'}></Progress>
                        </div>
                    )
                })}

                {datas.length<=0&&this.getNoDataView()}

            </div>
        )
    }


    getData(store) {

        let store_id=''

        if(store){
            if(store instanceof Array){
             
                store.map((item,index)=>{
                   if(index==0){
                       store_id=store_id+item.id
                   }else {
                       store_id=store_id+','+item.id
                   } 
                })
    
            }else{
               
         
               store_id=store.id 
            }
        }
        Api.getSpecTopTen({ time: this.state.selectTime.value, keyword: this.state.type,store_id:store_id }).then(res => {
            if (res.data.code == 0) {
                let list = res.data.data.spec_order_list;
                let total = 0
                list.map(item => {
                    total = total + item.count
                })

                this.setState({ datas: list, total: total })
            }
        })
    }

    getNoDataView(){
        return (
            <div style={{textAlign:'center',marginTop:300,fontSize:18}}>没有数据</div>
        )
    }

}


