
import axios from 'axios';


export default {
    /**
     * 密码登录
     * @param {*} params 
     */
    LoginPassword(params) {
        return axios.put(`api/user/login/`, params)
    },

    /**
     * 获取登录验证码
     * 
     * @param {*} params 
     */
    getLoginCode(params) {
        return axios.get(`/api/user/enter/sms/`, { params: params })
    },

    /**
       * 验证码登录
       * @param {*} params 
       */
    LoginCode(params) {
        return axios.put(`/api/user/sms/login/`, params)
    },


    /**
      * 获取找回密码验证码
      * 
      * @param {*} params 
      */
    getFindCode(params) {
        return axios.get(`/api/user/retrieve/sms/`, { params: params })
    },

    //找回密码
    FindPassword(params) {
        return axios.post(`/api/user/retrieve/password/`, params)
    },

    //获取修改密码验证码
    getUpdatePasswordCode(params) {
        return axios.get(`/api/user/modify/sms/`, { params: params })
    },

    //修改密码
    UpdatePassword(params) {
        return axios.post(`/api/user/update/password/`, params)
    },

    //获取当前用户信息
     

}