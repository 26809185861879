import React, { Component } from 'react';

import str from '../../str';
import styles from './store.module.css'
import StoreDropList from '../../component/droplist'
import Store_Attribute from './store_attribute'
import Store_Sale from './store_sale'
import Store_Select from './store_select'
import Api from '../../api/dataover_store'
import DropListMore from '../../component/droplistmore'
import { Input } from 'antd';
import { AudioOutlined } from '@ant-design/icons';

const { Search } = Input;

//门店分析
export default class Store extends Component {
    constructor(props) {
        super();
        this.state = {
            storelist: [],
            Type: str.AllStore,
            selectStoreforSingle: null,//单店分析选择的门店
            selectmore: [],//多店分析

        };
        this.storeList=[];

    }

    componentDidMount() {

        this.getStoreList();

    }
    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }
    render() {
        let {
            storelist,
            Type,
            selectStoreforSingle,
            selectmore
        } = this.state
        let user = JSON.parse(sessionStorage.getItem(str.UserDataKey))

        return (
            <div style={{ backgroundColor: '#32384b' }}>
                <div className={styles.container}>
                    <div className={styles.Left}>
                        <div style={{ fontSize: 18, color: Type == str.SingleStore ? '#00c8a9' : "white", padding: '10px 0' }} onClick={() => { this.setState({ Type: str.SingleStore }) }}>单店分析</div>
                        {Type == str.SingleStore && (<StoreDropList showTooltip={true} height={600} select={selectmore.length > 0 ? selectmore[0] : null} datas={storelist} selectOnClick={(item) => {
                            let temp = selectmore
                            if (selectmore.indexOf(item) > -1) {
                                temp = []
                            } else {
                                temp = []
                                temp.push(item)
                            }     
                            this.setState({ selectmore: temp })
                            this.props.showLoad()
                        }}   ></StoreDropList>)}
                        {user.permissions.indexOf(1006) > -1 && (<div style={{ fontSize: 18, color: Type == str.MoreStore ? '#00c8a9' : "white", padding: '10px 0' }} onClick={() => { this.setState({ Type: str.MoreStore }) }}>多店分析</div>)}
                        {Type == str.MoreStore && (<DropListMore showTooltip={true}   height={600} select={selectmore} datas={storelist} selectOnClick={(item) => {
                            let temp = []
                            selectmore.map(item => {
                                temp.push(item)
                            })
                            if (temp.indexOf(item) > -1) {
                                let ids = temp.indexOf(item)
                                temp.splice(ids, 1)

                            } else {
                                temp.push(item)
                            }

                            this.setState({ selectmore: temp })
                            this.props.showLoad()
                        }}   ></DropListMore>)}

                        <div style={{position:'relative',right:15}}>
                        <Search  disabled={!(Type==str.MoreStore||Type==str.SingleStore)}  placeholder="搜索门店" 
                        onPressEnter={event=>{ this.doSearchStore(event.target.value)}}
                        onChange={event=>{if(event.target.value=='')  {this.setState({storelist:this.storeList})} }}
                        onSearch={value => {this.doSearchStore(value) }} enterButton />
                        </div>
                    </div>

                    <div className={styles.Right}>
                        { /* Type==str.MoreStore&&(<Store_Select></Store_Select>) */}
                        <Store_Attribute store={selectmore}></Store_Attribute>
                        <Store_Sale store={selectmore}></Store_Sale>
                    </div>

                </div>
            </div>
        )
    }


    doSearchStore(value){
       let temps=[]
       this.storeList.map(item=>{
          if(item.name.indexOf(value)>-1){
             temps.push(item) 
          } 
       })
      this.setState({storelist:temps})     
       
    }


    getStoreList() {


        Api.getStoreList().then(res => {
            if (res.data.code == 0) {
                this.setState({ storelist: res.data.data.stores })
                this.storeList=res.data.data.stores
            } else {

            }
        })

    }



}



