import React, { Component } from 'react';

import str from '../../str';
import styles from './total.module.css'

import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import List from 'react-virtualized/dist/commonjs/List';
import Api from '../../api/dataover'
import util from '../../Util'
import Progress from '../../component/progress'

//总览-累计数据
export default class Total_RealData extends Component {
    constructor(props) {
        super();
        this.state = {
            type: str.Check,
            datas: [],
            checkArr:[],
            transfrom_number:0,//转化数量
            tire_sale_number:0,//轮胎销售数
            tire_service_number:0,//轮胎服务数
            transfrom_amount:0,//转化金额
            chances:0,//
            handle_number:0//
        };

    }

    componentDidMount() {
        this.getdata();
        this.circle = setInterval(() => {
            this.getdata();
        }, 1000*15);


    }

    componentWillUnmount() {
        clearInterval(this.circle)
        this.setState = (state, callback) => {
            return;
        };
     
      
    }
    render() {
        let {
            checkArr,
            transfrom_number,
            tire_sale_number,
            tire_service_number,
            transfrom_amount,
            chances,
            handle_number
        } = this.state


        return (
            <div className={styles.totalContainer}>
                <div className={styles.total_left}>
                    <div style={{ paddingTop: 10, paddingLeft: 20}}>
                        <div className={styles.flex_row} >
                            <div style={{ height: 30, width: 2, backgroundColor: '#00c9b2' }}> </div>
                            <div style={{ fontSize: 18, marginLeft: 10, color: "white" }} >累计数据</div>
                        </div>

                        <p style={{ color: "white", marginTop: 15, marginBottom: 15 }}>检测量:</p>

                        {this.getChcekUi(checkArr)}
                    </div>

                    <div>
                        <p style={{ color: "white", fontSize: 16, marginTop: 35 }}>转化率:<span style={{ fontSize: 20, marginLeft: 5 }}>{util.calculate100(transfrom_number,chances)}</span>%</p>
                        <p style={{ color: "white", fontSize: 16, marginTop: 35 }}>处理率:<span style={{ fontSize: 20, marginLeft: 5 }}>{util.calculate100(handle_number,chances)}</span>%</p>
                    </div>

                </div>
               
               <div className={styles.total_right}>
                <div className={styles.linev}></div>
                <div className={styles.total_item}>
                    <div className={styles.flex_row}>
                        <img src={require('../../asset/dataover/Cars.png')} style={{ height: 32, width: 32 }}></img>
                        <span style={{ marginLeft: 10, fontSize: 18, color: "white" ,paddingTop:5}}>转化车次</span>
                    </div>

                    <div style={{ fontSize: 28, marginTop: 32, color: "white" ,marginBottom:15}}>{transfrom_number}<span style={{fontSize:16}}>辆</span></div>
                    <Progress height={4} width={180}  secondColor={'#00c8a9'} percentageNum={65} background={'#aaaba8'}></Progress>
                </div>

                <div className={styles.linev}></div>

                <div className={styles.total_item}>
                    <div className={styles.flex_row}>
                        <img src={require('../../asset/dataover/tire_sale.png')} style={{ height: 32, width: 32 }}></img>
                        <span style={{ marginLeft: 10, fontSize: 18, color: "white" ,paddingTop:5}}>轮胎销售</span>
                    </div>

                    <div style={{ fontSize: 28, marginTop: 32, color: "white" ,marginBottom:15}}>{tire_sale_number}<span style={{fontSize:16}}>条</span></div>
                    <Progress height={4} width={180}  secondColor={'#15cbfa'} percentageNum={65} background={'#aaaba8'}></Progress>
                </div>
                <div className={styles.linev}></div>

                <div className={styles.total_item}>
                    <div className={styles.flex_row}>
                        <img src={require('../../asset/dataover/tire_service.png')} style={{ height: 32, width: 32 }}></img>
                        <span style={{ marginLeft: 10, fontSize: 18, color: "white",paddingTop:5 }}>轮胎服务</span>
                    </div>

                    <div style={{ fontSize: 28, marginTop: 32, color: "white",marginBottom:15 }}>{tire_service_number}<span style={{fontSize:16}}>辆</span></div>
                    <Progress height={4} width={180}  secondColor={'#15cbfa'} percentageNum={65} background={'#aaaba8'}></Progress>
                </div>
                <div className={styles.linev}></div>

                <div className={styles.total_item}>
                    <div className={styles.flex_row}>
                        <img src={require('../../asset/dataover/Money.png')} style={{ height: 32, width: 32 }}></img>
                        <span style={{ marginLeft: 10, fontSize: 18, color: "white",paddingTop:5 }}>转化金额</span>
                    </div>

                    <div style={{ fontSize: 28, marginTop: 32, color: "white" ,marginBottom:15}}>{transfrom_amount}<span style={{fontSize:16}}>元</span></div>
                    <Progress height={4} width={180}  secondColor={'#00c8a9'} percentageNum={65} background={'#aaaba8'}></Progress>
                </div>
                </div>

            </div>
        )
    }


    getdata() {
       Api.getTotalData().then((res)=>{
           if(res.data.code==0){
               let checkArr=this.dealCheck(res.data.data.device_total)

               let data=res.data.data
               this.setState({
               checkArr:checkArr ,
               transfrom_number:data.transfrom_number,
               tire_sale_number:data.tire_sale_number,
               tire_service_number:data.tire_service_number,
               handle_number:data.handle_number,
               chances:data.chances,
               handle_number:data.handle_number,
               transfrom_amount:data.transfrom_amount
            })
           }else {
             
           }
       },()=>{})
    }


    dealCheck(data){
       let str =data.toString(); 
       let arr=[0, 0, 0, ',', 0, 0, 0, ',', 0, 0, 0]
       let dataArr=str.split('').reverse()
       dataArr.map((item,index)=>{
            if(index>=0&&index<=2){
                arr[index]=item
            }else if(index>2&&index<=6){
                arr[index+1]=item
            }else {
               arr[index+2]=item 
            } 
       }) 

      return arr.reverse() 
    }

    getChcekUi(arr) {
      
        return (
            <div className={styles.checkNumContainer}>
                {arr.map((item, index) => {
                    return (<div key={index} className={item !== ',' ? index==2||index==6||index==10 ?styles.numberSingle1: styles.numberSingle : styles.douhao} onClick={() => { this.setState({ selectpage: item }) }}>{item}</div>)
                })}
            </div>
        )

    }



}


